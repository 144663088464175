<template>
  <div>
    <div class="top-header blackBG border-bottom">
      <div class="marquee border-top-0 border-bottom">
        <banner-slider></banner-slider>
      </div>
    </div>
    <inner-banner
      :loadChart="loadChart"
      :mn="mn"
      v-model="chartKeys"
    ></inner-banner>
    <section class="blockElement overflow-hidden whiteBG mainPad">
      <div class="container">
        <div class="row justify-content-between position-relative">
          <div class="col-12 col-xl-4 max-30 mb-4 mb-lg-0 pb-0" :style="`height: ${height}px`">
            <left-bar></left-bar>
          </div>
          <div class="col-12 col-xl-8 max-70 pt-4 halfBG pb-70 pl-70" ref="rightBlock" >
            <latest-news :page="'instrument'"></latest-news>
            <div class="spacer"></div>
            <!-- <chart-patterns></chart-patterns> -->
            <div class="spacer"></div>
            <market-insights></market-insights>
            <!-- <div class="spacer"></div>
            <cross-pairs></cross-pairs>
            <div class="spacer"></div>
            <app-correlation></app-correlation> -->
            <div class="spacer"></div>
            <monthly-returns :loadChart="loadChart" :mn="mn"></monthly-returns>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import bannerslider from "../../components/shared/banner_slider";
import innerbanner from "./files/inner_banner";
import leftbar from "./files/left_bar";
//import latestnews from "./files/latest_news";
import chartpatterns from "../home/files/chart_patterns";
import marketinsights from "./files/market_insights";
import crosspairs from "./files/cross_pairs";
import appcorrelation from "./files/correlation";
import monthlyreturns from "./files/monthly_returns";
import latestnews from "../home/files/latest_news";
export default {
  data() {
    return {
      loadChart: "BTC-USD",
      mn: "BTC-USD",
      chartKeys: { loadChart: "BTC-USD", mn: "BTC-USD" },
      height: 100,
    };
  },
  watch: {
    chartKeys(val) {
      this.loadChart = val.loadChart;
      this.mn = val.mn;
    },
    "store.calendarData"(val) {
        this.height = this.$refs.rightBlock.clientHeight;
    },
  },
  components: {
    bannerSlider: bannerslider,
    innerBanner: innerbanner,
    leftBar: leftbar,
    latestNews: latestnews,
    chartPatterns: chartpatterns,
    marketInsights: marketinsights,
    crossPairs: crosspairs,
    appCorrelation: appcorrelation,
    monthlyReturns: monthlyreturns,
  },
  created() {
    if (this.$route.query.market) {
      let chartKeys = {
          loadChart: this.$route.query.market,
          mn: this.$route.query.market,
        },
        mn = this.$route.query.market;//mn
      if (mn) {
        chartKeys.mn = mn;
      }
      this.chartKeys = chartKeys;
    }
  },
};
</script>

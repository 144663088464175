<template>
  <div class="row">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <h3 class="mb">Monthly Returns (%)</h3>
        <!-- <ul class="linksDrop d-flex align-items-center">
                    <li class="me-3">
                        <div class="selectDark">
                            <select class="form-control form-select">
                                <option value="">Select Month</option>
                                <option selected value="1">Janaury</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                        </div>
                    </li>
                    <li>
                        <div class="selectDark">
                            <select class="form-control">
                                <option>Change / ...</option>
                            </select>
                        </div>
                    </li>
                </ul> -->
      </div>
      <div
        v-if="store.monthlyReturnData.length"
        class="heatMap-layout table-responsive"
      >
        <table class="table" id="js-datatable">
          <thead>
            <tr>
              <th>Time</th>
              <th v-for="month of months" :key="month">{{ month }}</th>
              <!-- <th>Feb</th>
              <th>Mar</th>
              <th>Apr</th>
              <th>May</th>
              <th>Jun</th>
              <th>Jul</th>
              <th>Aug</th>
              <th>Sep</th>
              <th>Oct</th>
              <th>Nov</th>
              <th>Dec</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="year of years" :key="year">
              <td>{{ year }}</td>
              <td
                :class="returnForSelectedMonth(year, month).class"
                v-for="month of months"
                :key="month"
              >
                {{ returnForSelectedMonth(year, month).change }}
              </td>
            </tr>
            <!-- <tr>
              <td>2021</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="redBG">-8.60%</td>
              <td class="redBG">-8.60%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>2020</td>
              <td class="greenBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
            </tr>
            <tr>
              <td>2019</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
            </tr>
            <tr>
              <td>2018</td>
              <td class="greenBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
            </tr>
            <tr>
              <td>2017</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
            </tr>
            <tr>
              <td>2016</td>
              <td class="greenBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
            </tr>
            <tr>
              <td>2015</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="greenBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
              <td class="redBG">+14.51%</td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { useMainStore } from "@/store";

export default {
  setup() {
    const store = useMainStore();
    return { store };
  },
  props: ["loadChart", "mn"],
  data() {
    return {
      years: [],
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    };
  },
  methods: {
    formatDate(data) {
      let now = new Date(data);
      let year = "" + now.getFullYear();
      let month = "" + (now.getMonth() + 1);
      if (month.length == 1) {
        month = "0" + month;
      }
      let day = "" + now.getDate();
      if (day.length == 1) {
        day = "0" + day;
      }
      let hour = "" + now.getHours();
      if (hour.length == 1) {
        hour = "0" + hour;
      }
      let minute = "" + now.getMinutes();
      if (minute.length == 1) {
        minute = "0" + minute;
      }
      return year + month + day + hour + minute;
    },
    getOneMonthData() {
      var prev = new Date();
      prev.setFullYear(prev.getFullYear() - 10);
      let onePev = new Date(prev);
      onePev.setDate(onePev.getDate() - 1);
      let link = process.env.VUE_APP_LIVE_CHART_URL;
      let formData = {
        isin: this.mn,
        startdate: this.formatDate(onePev),
        enddate: this.formatDate(new Date()),
        interval: "1month",
        link: link,
      };
      this.store
        .getChartData(formData, false)
        .then((response) => {})
        .catch();
    },
    returnForSelectedMonth(year, month) {
      let currentMonth = moment(`${year}-${month}-01`, "YYYY-MMM-DD").format(
        "YYYY-MM-DD"
      );
      let previousMonth = moment(currentMonth)
        .subtract(1, "month")
        .format("YYYY-MM-DD");
        // console.log(previousMonth)
      let currentCandle = this.store.monthlyReturnData.find(
        (i) => i.time.split("T")[0] == currentMonth
      );
      let previousCandle = this.store.monthlyReturnData.find(
        (i) => i.time.split("T")[0] == previousMonth
      );
      if (moment().diff(moment(currentCandle)) > 0) {
        if (currentCandle && previousCandle) {
          // console.log(currentCandle)
          // let gains =
          //   ((currentCandle.close - previousCandle.close) /
          //     previousCandle.close) *
          //   100;
          let gains =
            ((currentCandle.close - currentCandle.open) /
              currentCandle.open) *
            100;
          return {
            change: `${this.COMMON.formatPrice(gains)}%`,
            class:
              this.COMMON.formatPrice(gains) > 0
                ? "greenBG"
                : this.COMMON.formatPrice(gains) < 0
                ? "redBG"
                : "",
          };
        } else {
          return { change: "N/A", class: "" };
        }
      } else {
        return { change: "", class: "" };
      }
    },
  },
  created() {
    this.getOneMonthData();
    for (let i = 0; i < 10; i++) {
      this.years.push(moment().subtract(i, "year").format("YYYY"));
    }
  },
};
</script>

<template>
    <span v-show="false">{{ create_liveCandle }}</span>
    <section class="blockElement innerPage-banner secondaryBG border-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadCrum d-flex align-items-center justify-content-between">
                        <div class="bdPage">
                            <h2>Instrument Description</h2>
                            <nav aria-label="breadcrumb mt-2">
                                <ol class="breadcrumb mb-0">
                                    <li class="breadcrumb-item">
                                        <router-link to="market-trend" target="_blank">Markets</router-link>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">
                                        Instrument Description
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <!--  <div class="rightSearch hideMobile">
              <form class="searchBar">
                <div class="form-group position-relative inputIcon mb-0">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search Quotes"
                    name=""
                  />
                  <a class="searchButton" href="#"
                    ><vue-feather type="search"></vue-feather>
                  </a>
                </div>
              </form>
            </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement bg-white pb-5">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="whiteBG chartArea overflow-hidden">
                        <div class="row no-gutters justify-content-between mainChart position-relative">
                            <div class="col-xl-9 mb-4 mb-xl-0 leftArea-chart full-scr">
                                <div class="chartHeader d-flex align-items-center justify-content-between border-bottom p-2 p-md-3">
                                    <div class="d-flex align-items-center">
                                        <div class="d-flex align-items-center me-4">
                                            <div class="dualIcon flex-shrink-0">
                                                <span class="d-flex align-items-center" v-if="instruments[loadChart]">
                                                    <a class="d-inline-flex me-2" href="#" v-if="instruments[loadChart].other.type != 'INDICIES'">
                                                        <img
                                                            :src="
                                  'assets/images/crypto/' +
                                  splitCurrency(instruments[loadChart].m, 0) +
                                  '.webp'
                                "
                                                            alt=""
                                                            style="height: 20px; width: 20px;"
                                                        />
                                                    </a>
                                                    <a class="d-inline-flex mx-2" href="#" v-else>
                                                        <img
                                                            :src="
                                  'assets/images/crypto/' +
                                  splitCurrency(
                                    instruments[loadChart].other.isinid,
                                    1
                                  ) +
                                  '.webp'
                                "
                                                            alt=""
                                                            style="height: 20px; width: 20px;"
                                                        />
                                                    </a>
                                                    <a class="d-inline-flex mx-2" href="#" v-if="instruments[loadChart].other.type != 'INDICIES'">
                                                        <img
                                                            :src="
                                  'assets/images/crypto/' +
                                  splitCurrency(instruments[loadChart].m, 1) +
                                  '.webp'
                                "
                                                            alt=""
                                                            style="height: 20px; width: 20px;"
                                                        />
                                                    </a>
                                                </span>
                                            </div>
                                            <div class="flex-grow-1 ms-2">
                                                <h6 class="mb-0 f-18 medium" v-if="instruments[loadChart]">
                                                    {{ instruments[loadChart].other.Description.split( "vs" )[0] }}/{{ instruments[loadChart].other.Description.split( "vs" )[1] }}
                                                </h6>
                                                <p class="mb-0 f-12" v-if="instruments[loadChart]">
                                                    {{ loadChart }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="gainLoss d-flex">
                                            <h6 class="mb-0 medium" v-if="instruments[loadChart]">
                                                {{ instruments[loadChart].B }}
                                            </h6>
                                            <span class="f-14 split me-md-2 ms-md-1 pt-md-1" v-if="instruments[loadChart]">{{ instruments[loadChart].other.Tick_Value.split(" ")[1] }}</span>
                                            <span
                                                class="f-14 d-flex align-items-center mob-small-font"
                                                :class="{
                            green: instruments[loadChart].CB.toFixed(2) > 0,
                            red: instruments[loadChart].CB.toFixed(2) < 0,
                          }"
                                                v-if="instruments[loadChart]"
                                            >
                                                {{ instruments[loadChart].CB.toFixed(2) }}%
                                                <i
                                                    class="ms-2 fa"
                                                    :class="{
                              'fa-caret-up':
                                instruments[loadChart].CB.toFixed(2) > 0,
                              'fa-caret-down':
                                instruments[loadChart].CB.toFixed(2) < 0,
                            }"
                                                    aria-hidden="true"
                                                ></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="volumeTrade hideMobile">
                                        <span class="progress custom overflow-visible position-relative" v-if="instruments[loadChart]">
                                            <div :style="getProgressRange.progressBar">
                                                <span class="triangle d-flex align-items-center" :style="getProgressRange.icon" :class="getProgressRange.class"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                                            </div>
                                            <span class="d-flex align-items-center justify-content-between position-absolute gdr">
                                                <span class="f-12" v-if="ChartValues.Low">
                                                    <span class="iconColor">Low: </span>
                                                    <b>{{ ChartValues.Low }}</b>
                                                </span>
                                                <span class="f-12" v-if="ChartValues.High">
                                                    <span class="iconColor">High: </span>
                                                    <b>{{ ChartValues.High }}</b>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                    <!-- <div class="volumeTrade"> <span class="progress custom overflow-visible justify-content-center" v-if="instruments[loadChart]" > <div class="progress-bar greenBG" :style="getProgressRange" > </div><span class="d-flex align-items-center justify-content-between position-absolute gdr" > <span class="f-12" v-if="ChartValues.Low"> <span class="iconColor">Low: </span> <b>{{ChartValues.Low}}</b> </span> <span class="f-12" v-if="ChartValues.High"> <span class="iconColor">High: </span> <b>{{ChartValues.High}}</b> </span> </span> </span> </div>-->
                                    <div class="d-flex align-items-center ms-md-3 followProcess">
                                        <!-- <a href="#" class="gray_btn">Follow</a> -->
                                        <a v-on:click="openFullscreen()" href="#" class="gray_btn ms-2 d-flex align-items-center">
                                            <vue-feather type="maximize"></vue-feather>
                                        </a>
                                    </div>
                                </div>
                                <div class="position-relative">
                                    <!-- <ul class="paginationLink d-inline-flex align-items-center m-0">
                                        <li><a class="active" href="#">1D</a></li>
                                        <li><a href="#">7D</a></li>
                                        <li><a href="#" @click="changeNewChart()">1M</a></li>
                                        <li><a href="#">3M</a></li>
                                        <li><a href="#">YTD</a></li>
                                    </ul> -->
                                    <div class="chartAdd w-100 d-block pt-4 chartContainer" id="chartContainer" style="height: 300px;"></div>
                                </div>
                            </div>
                            <div class="col-12 col-xl-3 rightArea-chart">
                                <div class="chartHeader position-relative d-flex align-items-center justify-content-between border-bottom p-3 ps-0">
                                    <!-- <div class="graphValue d-flex align-items-center">
                    <a
                      @click="sharevote = !sharevote"
                      href="javascript:void(0)"
                      class="grd_btn py-2 me-2"
                      >Vote</a
                    >
                    <a href="javascript:void(0)" class="gray_btn icon_btn">
                      <vue-feather type="clock"></vue-feather>
                    </a>
                  </div> -->
                                    <router-link to="/economy-overview" class="gray_btn nowrap f-12">Economy Overview </router-link>
                                </div>
                                <ul class="chartGT position-relative mb-0" :class="sharevote || showVoteChart ? 'd-none' : ''">
                                    <li class="d-flex align-items-center justify-content-between" v-if="instruments[loadChart] && Object.keys(store.trendingIsins).length && store.trendingIsins[loadChart]">
                                        <h6 class="d-flex align-items-center flex-none mb-0">1W</h6>
                                        <span class="valueChart w-100">
                                            <span class="progress custom overflow-visible justify-content-center">
                                                <div class="progress-bar overflow-visible" :style="weekprogressChart.progressBar" :class="[{'topDym' : !weekprogressChart.low && !weekprogressChart.high}]">
                                                    <span class="triangle d-flex align-items-center" :style="weekprogressChart.icon" :class="weekprogressChart.class">
                                                        <i class="fa fa-caret-down" aria-hidden="true"></i> <span v-if="weekprogressChart.low"> Near 1wk low</span> <span v-if="weekprogressChart.high"> Near 1wk high</span>
                                                    </span>
                                                </div>
                                                <span class="d-flex align-items-center justify-content-between position-absolute gdr">
                                                    <span class="f-12" v-if="typeof JSON.parse(store.trendingIsins[loadChart].weekly) == 'object' && JSON.parse(store.trendingIsins[loadChart].weekly).low">
                                                        <span class="iconColor">Low </span>
                                                        <b v-if="isMonday() && ChartValues.Low">{{parseFloat(ChartValues.Low).toFixed(5)}}</b>
                                                        <b v-else-if="ChartValues.Low && (parseFloat(ChartValues.Low) < parseFloat(JSON.parse(store.trendingIsins[loadChart].weekly).low))">{{parseFloat(ChartValues.Low).toFixed(5)}}</b>
                                                        <b v-else>{{parseFloat(JSON.parse(store.trendingIsins[loadChart].weekly).low).toFixed(5)}}</b>
                                                    </span>
                                                    <span class="f-12" v-if="typeof JSON.parse(store.trendingIsins[loadChart].weekly) == 'object' && JSON.parse(store.trendingIsins[loadChart].weekly).high">
                                                        <span class="iconColor">High </span>
                                                        <b v-if="isMonday() && ChartValues.High">{{parseFloat(ChartValues.High).toFixed(5)}}</b>
                                                        <b v-else-if="ChartValues.High && (parseFloat(ChartValues.High) > parseFloat(JSON.parse(store.trendingIsins[loadChart].weekly).high))">{{parseFloat(ChartValues.High).toFixed(5)}}</b>
                                                        <b v-else>{{parseFloat(JSON.parse(store.trendingIsins[loadChart].weekly).high).toFixed(5)}}</b>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                        <p
                                            class="flex-none mb-0 d-flex align-items-center"
                                            :class="{red: ((instruments[loadChart].B-parseFloat(JSON.parse(store.trendingIsins[loadChart].weekly).open))/parseFloat(JSON.parse(store.trendingIsins[loadChart].weekly).open))*100 < 0.00,green: ((instruments[loadChart].B-parseFloat(JSON.parse(store.trendingIsins[loadChart].weekly).open))/parseFloat(JSON.parse(store.trendingIsins[loadChart].weekly).open))*100 > 0.00}"
                                            v-if="typeof JSON.parse(store.trendingIsins[loadChart].weekly) == 'object' && JSON.parse(store.trendingIsins[loadChart].weekly).open"
                                        >
                                            <i class="fa me-1" aria-hidden="true" :class="(((instruments[loadChart].B-parseFloat(JSON.parse(store.trendingIsins[loadChart].weekly).open))/parseFloat(JSON.parse(store.trendingIsins[loadChart].weekly).open))*100 > 0.00) ? 'fa-caret-up' : 'fa-caret-down'"></i>
                                            {{parseFloat(((instruments[loadChart].B-parseFloat(JSON.parse(store.trendingIsins[loadChart].weekly).open))/parseFloat(JSON.parse(store.trendingIsins[loadChart].weekly).open))*100).toFixed(2)}}%
                                        </p>
                                    </li>
                                    <li class="d-flex align-items-center justify-content-between" v-if="instruments[loadChart] && Object.keys(store.trendingIsins).length && store.trendingIsins[loadChart]">
                                        <h6 class="d-flex align-items-center flex-none mb-0">1M</h6>
                                        <span class="valueChart w-100">
                                            <span class="progress custom overflow-visible justify-content-center">
                                                <div class="progress-bar overflow-visible" :style="monthprogressChart.progressBar" :class="[{'topDym' : !monthprogressChart.low && !monthprogressChart.high}]">
                                                    <span class="triangle d-flex align-items-center" :style="monthprogressChart.icon" :class="monthprogressChart.class">
                                                        <i class="fa fa-caret-down" aria-hidden="true"></i> <span v-if="monthprogressChart.low"> Near 1mn low</span> <span v-if="monthprogressChart.high"> Near 1mn high</span>
                                                    </span>
                                                </div>
                                                <span class="d-flex align-items-center justify-content-between position-absolute gdr">
                                                    <span class="f-12" v-if="typeof JSON.parse(store.trendingIsins[loadChart].monthly) == 'object' && JSON.parse(store.trendingIsins[loadChart].monthly).low">
                                                        <span class="iconColor">Low </span>
                                                        <b v-if="isMonthFirstDay() && ChartValues.Low">{{parseFloat(ChartValues.Low).toFixed(5)}}</b>
                                                        <b v-else-if="ChartValues.Low && (parseFloat(ChartValues.Low) < parseFloat(JSON.parse(store.trendingIsins[loadChart].monthly).low))">{{parseFloat(ChartValues.Low).toFixed(5)}}</b>
                                                        <b v-else>{{parseFloat(JSON.parse(store.trendingIsins[loadChart].monthly).low).toFixed(5)}}</b>
                                                    </span>
                                                    <span class="f-12" v-if="typeof JSON.parse(store.trendingIsins[loadChart].monthly) == 'object' && JSON.parse(store.trendingIsins[loadChart].monthly).high">
                                                        <span class="iconColor">High </span>
                                                        <b v-if="isMonthFirstDay() && ChartValues.High">{{parseFloat(ChartValues.High).toFixed(5)}}</b>
                                                        <b v-else-if="ChartValues.High && (parseFloat(ChartValues.High) > parseFloat(JSON.parse(store.trendingIsins[loadChart].monthly).high))">{{parseFloat(ChartValues.High).toFixed(5)}}</b>
                                                        <b v-else>{{parseFloat(JSON.parse(store.trendingIsins[loadChart].monthly).high).toFixed(5)}}</b>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                        <p
                                            class="flex-none mb-0 d-flex align-items-center"
                                            :class="{red: ((instruments[loadChart].B-parseFloat(JSON.parse(store.trendingIsins[loadChart].monthly).open))/parseFloat(JSON.parse(store.trendingIsins[loadChart].monthly).open))*100 < 0.00,green: ((instruments[loadChart].B-parseFloat(JSON.parse(store.trendingIsins[loadChart].monthly).open))/parseFloat(JSON.parse(store.trendingIsins[loadChart].monthly).open))*100 > 0.00}"
                                            v-if="typeof JSON.parse(store.trendingIsins[loadChart].monthly) == 'object' && JSON.parse(store.trendingIsins[loadChart].monthly).open"
                                        >
                                            <i class="fa me-1" aria-hidden="true" :class="(((instruments[loadChart].B-parseFloat(JSON.parse(store.trendingIsins[loadChart].monthly).open))/parseFloat(JSON.parse(store.trendingIsins[loadChart].monthly).open))*100 > 0.00) ? 'fa-caret-up' : 'fa-caret-down'"></i>
                                            {{parseFloat(((instruments[loadChart].B-parseFloat(JSON.parse(store.trendingIsins[loadChart].monthly).open))/parseFloat(JSON.parse(store.trendingIsins[loadChart].monthly).open))*100).toFixed(2)}}%
                                        </p>
                                    </li>
                                    <!-- <li class="d-flex align-items-center justify-content-between">
                    <h6 class="d-flex align-items-center flex-none mb-0">3M</h6>
                    <span class="valueChart w-100 px-3">
                      <span
                        class="progress custom overflow-visible justify-content-center"
                      >
                        <div
                          class="progress-bar redBG"
                          style="width: 40%; height: 5px"
                        >
                          <span class="triangle d-flex align-items-center red"
                            ><i
                              class="fa fa-caret-down me-2"
                              aria-hidden="true"
                            ></i>
                            Near 1wk low</span
                          >
                        </div>
                        <span
                          class="d-flex align-items-center justify-content-between position-absolute gdr"
                        >
                          <span class="f-12"
                            ><span class="iconColor">Low </span>
                            <b>1.10054</b></span
                          >
                          <span class="f-12"
                            ><span class="iconColor">High </span>
                            <b>1.30872</b></span
                          >
                        </span>
                      </span>
                    </span>
                    <p class="flex-none mb-0 d-flex align-items-center red">
                      <i class="fa fa-caret-down me-1" aria-hidden="true"></i>
                      0.45%
                    </p>
                  </li> -->
                                    <li class="d-flex align-items-center justify-content-between" v-if="instruments[loadChart] && Object.keys(store.trendingIsins).length && store.trendingIsins[loadChart]">
                                        <h6 class="d-flex align-items-center flex-none mb-0">1Y</h6>
                                        <span class="valueChart w-100">
                                            <span class="progress custom overflow-visible justify-content-center">
                                                <div class="progress-bar overflow-visible" :style="yearprogressChart.progressBar" :class="[{'topDym' : !yearprogressChart.low && !yearprogressChart.high}]">
                                                    <span class="triangle d-flex align-items-center" :style="yearprogressChart.icon" :class="yearprogressChart.class">
                                                        <i class="fa fa-caret-down" aria-hidden="true"></i> <span v-if="yearprogressChart.low"> Near 1yr low</span> <span v-if="yearprogressChart.high"> Near 1yr high</span>
                                                    </span>
                                                </div>
                                                <span class="d-flex align-items-center justify-content-between position-absolute gdr">
                                                    <span class="f-12" v-if="typeof JSON.parse(store.trendingIsins[loadChart].yearly) == 'object' && JSON.parse(store.trendingIsins[loadChart].yearly).low">
                                                        <span class="iconColor">Low </span>
                                                        <b v-if="ChartValues.Low && (parseFloat(ChartValues.Low) < parseFloat(JSON.parse(store.trendingIsins[loadChart].yearly).low))">{{parseFloat(ChartValues.Low).toFixed(5)}}</b>
                                                        <b v-else>{{parseFloat(JSON.parse(store.trendingIsins[loadChart].yearly).low).toFixed(5)}}</b>
                                                    </span>
                                                    <span class="f-12" v-if="typeof JSON.parse(store.trendingIsins[loadChart].yearly) == 'object' && JSON.parse(store.trendingIsins[loadChart].yearly).high">
                                                        <span class="iconColor">High </span>
                                                        <b v-if="ChartValues.High && (parseFloat(ChartValues.High) > parseFloat(JSON.parse(store.trendingIsins[loadChart].yearly).high))">{{parseFloat(ChartValues.High).toFixed(5)}}</b>
                                                        <b v-else>{{parseFloat(JSON.parse(store.trendingIsins[loadChart].yearly).high).toFixed(5)}}</b>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                        <p
                                            class="flex-none mb-0 d-flex align-items-center"
                                            :class="{red: ((instruments[loadChart].B-parseFloat(JSON.parse(store.trendingIsins[loadChart].yearly).open))/parseFloat(JSON.parse(store.trendingIsins[loadChart].yearly).open))*100 < 0.00,green: ((instruments[loadChart].B-parseFloat(JSON.parse(store.trendingIsins[loadChart].yearly).open))/parseFloat(JSON.parse(store.trendingIsins[loadChart].yearly).open))*100 > 0.00}"
                                            v-if="typeof JSON.parse(store.trendingIsins[loadChart].weekly) == 'object' && JSON.parse(store.trendingIsins[loadChart].yearly).open"
                                        >
                                            <i class="fa me-1" aria-hidden="true" :class="(((instruments[loadChart].B-parseFloat(JSON.parse(store.trendingIsins[loadChart].yearly).open))/parseFloat(JSON.parse(store.trendingIsins[loadChart].yearly).open))*100 > 0.00) ? 'fa-caret-up' : 'fa-caret-down'"></i>
                                            {{parseFloat(((instruments[loadChart].B-parseFloat(JSON.parse(store.trendingIsins[loadChart].yearly).open))/parseFloat(JSON.parse(store.trendingIsins[loadChart].yearly).open))*100).toFixed(2)}}%
                                        </p>
                                    </li>
                                </ul>
                                <div class="vote text-center position-relative flex-column justify-content-center p-3" :class="sharevote ? 'd-flex' : 'd-none'">
                                    <div class="thoughts mb-4">
                                        <h4 class="">Share Your <span class="green">Thoughts</span></h4>
                                        <p>
                                            I think that the price of
                                            <strong class="green">Euro/US Dollar</strong> will be
                                        </p>
                                    </div>
                                    <ul class="mb-4 bull-list d-flex align-items-center justify-content-around">
                                        <li>
                                            <a @click="votingresult = 1" :class="votingresult == 1 ? 'active' : ''" href="javascript:void(0)">
                                                <img src="assets/images/bullish.svg" alt="Icon" title="Bullish" />
                                                <p class="medium">Bullish</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a @click="votingresult = 2" :class="votingresult == 2 ? 'active' : ''" href="javascript:void(0)">
                                                <img src="assets/images/neutral.svg" alt="Icon" title="Bullish" />
                                                <p class="medium">Neutral</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a @click="votingresult = 3" :class="votingresult == 3 ? 'active' : ''" href="javascript:void(0)">
                                                <img src="assets/images/bearish.svg" alt="Icon" title="Bullish" />
                                                <p class="medium">Bearish</p>
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="viewVoting" v-if="votingresult != 0">
                                        <p class="f-18">To View the Voting Result</p>
                                        <div class="d-flex align-items-center justify-content-center">
                                            <a data-bs-toggle="modal" data-bs-target="#exampleModalCenter" href="javascript:void(0)" class="grd_btn me-2 small">Sign in</a>
                                            <a data-bs-toggle="modal" data-bs-target="#exampleModalCenter" @click="votingresult = 4" href="javascript:void(0)" class="grd_btn small">Register</a>
                                        </div>
                                    </div>
                                    <a @click="sharevote = !sharevote" href="javascript:void(0)" class="position-absolute close">Close</a>
                                </div>
                                <div class="bulishLogin text-center" v-if="votingresult != 0 && showVoteChart">
                                    <img class="w-100" src="assets/images/chart/moonChart.svg" alt="Chart" title="Bullish" />
                                    <h6 class="medium mb-4">You are <span class="green">Bullish</span></h6>
                                    <ul class="d-flex align-items-center justify-content-between">
                                        <li>
                                            <span class="d-flex align-items-center green medium"><i class="me-1 f-16 fa fa-caret-up" aria-hidden="true"></i> 50%</span><span class="medium uppercase">Bullish</span>
                                        </li>
                                        <li>
                                            <span class="d-flex align-items-center medium"><i class="me-1 fa fa-circle" aria-hidden="true"></i> 20%</span><span class="medium uppercase">NEUTRAL</span>
                                        </li>
                                        <li>
                                            <span class="d-flex align-items-center red medium"><i class="me-1 f-16 fa fa-caret-down" aria-hidden="true"></i> 30%</span><span class="medium uppercase">BEARISH</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Login Modal -->
        <div class="modal fade" id="exampleModalCenter" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered max-600">
                <div class="modal-content">
                    <div class="modal-header pb-0 justify-content-end border-0">
                        <a href="javascript:void(0)" class="close" data-bs-dismiss="modal" aria-label="Close">
                            <vue-feather type="x"></vue-feather>
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="loginPortfolio h-100">
                            <h3 class="text-center mb-5">Log into Portfolios</h3>
                            <form class="login was-validated">
                                <!--Click submit add class "was-validated"-->
                                <div class="form-group">
                                    <label>Email Address</label>
                                    <input type="text" placeholder="Enter name" class="form-control" name="" required />
                                    <p class="text-danger mb-0">Enter your email address</p>
                                </div>
                                <div class="form-group pb-2">
                                    <label>Password</label>
                                    <input type="text" placeholder="Enter password" class="form-control" name="" />
                                </div>
                                <div class="form-group">
                                    <a
                                        href="javascript:void(0)"
                                        class="grd_btn w-100"
                                        data-bs-dismiss="modal"
                                        @click="
                      sharevote = false;
                      showVoteChart = true;
                    "
                                    >
                                        LOG IN
                                    </a>
                                </div>
                            </form>
                            <div class="or text-center mb-4 d-none">
                                <p class="mb-0 d-inline-flex">OR</p>
                            </div>
                            <div class="socialShare d-none">
                                <a href="javascript:void(0)" class="border-btn d-flex align-items-center justify-content-center">
                                    <img class="me-3" src="assets/images/google.svg" alt="Google" title="Continue with Google" />Continue with Google
                                </a>
                                <a href="javascript:void(0)" class="border-btn d-flex align-items-center justify-content-center">
                                    <img class="me-3" src="assets/images/apple.svg" alt="Apple" title="Continue with Apple" />Continue with Apple
                                </a>
                                <a href="javascript:void(0)" class="border-btn d-flex align-items-center justify-content-center">
                                    <img class="me-3" src="assets/images/facebook-cir.svg" alt="Facebook" title="Continue with Facebook" />Continue with Facebook
                                </a>
                            </div>
                            <div class="register text-center pb-4">
                                <router-link to="/register" class="green underline">CAN’T LOG IN?</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import * as _ from "@/chartiq";
    import * as __ from "@/quoteFeedSimulator";
    import { Const } from "@/plugins/vue-provider-cache/const.mod";
    var Stx = require("@/stx");
    import math from "@/math";
    import moment from "moment";
    import { useMainStore } from "@/store";
    export default {
        setup() {
            const store = useMainStore();
            window._ = _;
            window.__ = __;
            return { store };
            return {};
        },
        inheritAttrs: false,
        data() {
            return {
                basecurrency: "",
                sharevote: false,
                votingresult: 0,
                // chartType: 'USD-BTC',
                pageLoader: false,
                upButton: false,
                downButton: true,
                buySellType: "buy",

                loading: 1,
                tradeBuySell: false,

                stxx: "",
                intervalPeriod: 60,
                intervalTitle: {
                    "1M": "1 Minute",
                    "1H": "1 Hour",
                    "1D": "1 Day",
                    "1W": "1 Week",
                    "1MN": "1 Month",
                },
                chartInterval: [
                    { unit: 1, name: "minute", data: "1M" },
                    { unit: 1, name: "hour", data: "1H" },
                    { unit: 1, name: "day", data: "1D" },
                    { unit: 1, name: "week", data: "1W" },
                    { unit: 1, name: "month", data: "1MN" },
                ],
                defaultChartInterval: "1D",
                search: "",
                searchPopUp: false,
                ShowSearchPopup: false,
                ChartValues: { Open: "", High: "", Low: "", Close: "" },
                // fullscreen: false,
                tools: ["", "crossline", "trendline", "fibfan", "freeform", "annotation", "fibonacci", "gannfan", "average", "line", ""],
                chartType: "candle",
                chartTypelist: [
                    { type: "candle", value: "Candle", class: "icon-candle" },
                    {
                        type: "hollow_candle",
                        value: "Hollow Candle",
                        class: "hollow-candle",
                    },
                    { type: "mountain", value: " Mountain", class: "icon-area" },
                    { type: "colored_line", value: "Line", class: "icon-line" },
                    { type: "colored_bar", value: "Bar", class: "icon-bar" },
                    {
                        type: "baseline_delta",
                        value: "Baseline Delta",
                        class: "icon-baseLine",
                    },
                    // {type:'histogram', value:'Histogram', class:''},
                    // {type:'wave', value:'Wave', class:''}
                ],
                searchStudy: "",
                studiesAddedList: [],
                studies_List: {},
                alertTab: false,
                studyDialogOpened: false,
                chartReady: false,
                toolSelected: "-",
                ticks: 70,
                showLine: false,
                ShowUploads: false,
                showCreateLayout: false,
                saveAsLayout: false,
                new_layout_name: "",
                submitted: false,
                layoutSelected: "",
                saveCurrentlayout: false,
                renamelayout: {},
                studdyActive: false,
                errorImage: [],
                watchId: null,
                showVoteChart: false,
            };
        },
        props: ["loadChart", "mn"],
        watch: {
            /*appTheme(val){
            this.chartColor()
        },*/
            loadChart() {
                this.changeNewChart();
                this.chartReady = true;
                if (localStorage.getItem("watchId")) {
                    let watchId = localStorage.getItem("watchId");
                    this.watchId = watchId == "null" ? null : watchId;
                } else {
                    this.watchId = null;
                }
            },
        },
        methods: {
            isMonday() {
                if (parseInt(moment().day()) == 1) {
                    return true;
                } else {
                    return false;
                }
            },
            isMonthFirstDay() {
                if (moment().startOf("month").format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
                    return true;
                } else {
                    return false;
                }
            },
            splitCurrency(val, index) {
                if (val) {
                    if (val.length == 6) {
                        if (index) {
                            return val.slice(3, 6).toLowerCase();
                        } else {
                            return val.slice(0, 3).toLowerCase();
                        }
                    } else if (val.length >= 7) {
                        if (index) {
                            return val.slice(4, 7).toLowerCase();
                        } else {
                            return val.slice(0, 3).toLowerCase();
                        }
                    } else {
                        return "";
                    }
                }
            },
            //chart full screen
            openFullscreen() {
                if (document.fullscreenElement) {
                    document.exitFullscreen();
                } else {
                    var elem = document.querySelector(".full-scr");
                    if (elem.requestFullscreen) {
                        elem.requestFullscreen();
                    } else if (elem.webkitRequestFullscreen) {
                        /* Safari */
                        elem.webkitRequestFullscreen();
                    } else if (elem.msRequestFullscreen) {
                        /* IE11 */
                        elem.msRequestFullscreen();
                    }
                }
            },
            formatfloat(num) {
                // return num
                num = parseFloat(num);
                try {
                    return math.format(num, { notation: "fixed" }) || "";
                } catch (e) {
                    try {
                        return num.toFixed(8);
                    } catch (e) {
                        return "";
                    }
                }
            },
            addRemFev(item) {
                let _that = this;
                let watchId = null;
                if (localStorage.getItem("watchId")) {
                    watchId = localStorage.getItem("watchId");
                    this.watchId = watchId == "null" ? null : watchId;
                }
                let itemData = { isins: [item], id: watchId };
                let fevList = [];
                this.watchListData.forEach((val) => {
                    if (val._id === _that.watchId) {
                        fevList = val.is_fav ? val.is_fav : [];
                    }
                });
                if (fevList.includes(item)) {
                    fevList = fevList.filter((val) => val != item);
                    this.$store.getters[Const.GET_PROVIDERS]["priceFeed"].seeders["S_PF_PF"].removeFavorite(itemData);
                } else if (fevList.length <= 20) {
                    fevList.push(item);
                    this.$store.getters[Const.GET_PROVIDERS]["priceFeed"].seeders["S_PF_PF"].addFavorite(itemData);
                } else if (this.subList.length >= 20) {
                    this.displayError("you can add maximum 20 pair's in fevorite List", "error");
                }
                let watchListData = [...this.watchListData];
                watchListData.forEach((val, index) => {
                    if (val._id == _that.watchId) {
                        watchListData[index].is_fav = fevList;
                    }
                });
                this.$store.commit("/SET_WATCH_LIST", watchListData);
            },
            handleImgErr(val) {
                if (!this.errorImage.includes(val)) {
                    this.errorImage.push(val);
                }
            },
            layoutName() {
                return (this.getCurrentLayout().slice(0, 10) || "") + (this.getCurrentLayout().length > 10 ? "..." : "");
            },
            renameClick() {
                if (this.layoutSelected) {
                    this.renamelayout = this.getLayoutList().filter((item) => this.layoutSelected == item.id)[0];
                } else {
                    this.saveLayoutClick();
                }
            },
            saveRenamelayout() {
                let _that = this;
                let totalData = _that.getRawData();
                let getIndex = totalData.view_mode.chart_layout.findIndex((item) => _that.layoutSelected == item.id);
                totalData.view_mode.chart_layout[getIndex]["layout_name"] = this.renamelayout.layout_name;
                totalData.view_mode.chart_layout[getIndex]["lastModify"] = Date.now();
                _that._runSetRawObject({
                    globalParams: { setting: totalData },
                    localParams: { parameter: this },
                });
            },
            saveAsClick() {
                if (this.layoutSelected) {
                    let data = this.getLayoutList().filter((item) => this.layoutSelected == item.id)[0];
                    this.new_layout_name = (data.layout_name + " Copy").slice(0, 20);
                    this.saveAsLayout = true;
                    this.showCreateLayout = true;
                } else {
                    this.saveLayoutClick();
                }
            },
            getCurrentLayout() {
                try {
                    return this.getLayoutList().filter((item) => this.layoutSelected == item.id)[0].layout_name;
                } catch (e) {
                    return "";
                }
            },
            getLayoutList() {
                try {
                    return this.getRawData().view_mode.chart_layout;
                } catch (e) {
                    return [];
                }
            },
            rawObjectSaved() {
                //
                if (this.showCreateLayout) {
                    this.submitted = true;
                    this.saveAsLayout = true;
                    this.showCreateLayout = "";
                    this.new_layout_name = "";
                    this.displayError("New Layout Created successfully", "success");
                }
                if (this.saveCurrentlayout) {
                    this.displayError("Layout saved successfully", "success");
                    this.saveCurrentlayout = false;
                }
                if (this.ShowUploads) {
                    this.displayError("Layout Removed successfully", "success");
                    this.ShowUploads = false;
                }
                if (Object.keys(this.renamelayout).length > 0) {
                    this.displayError("Layout Renamed successfully", "success");
                    this.renamelayout = {};
                }
            },
            saveLayoutClick() {
                var drawingList = this.stxx.exportDrawings();
                var studiesList = this.stxx.exportLayout();
                if (drawingList.length || Object.keys(studiesList.studies).length > 0) {
                    if (this.layoutSelected) {
                        this.savelayout();
                    } else {
                        this.showCreateLayout = true;
                    }
                } else {
                    this.displayError("No Indicator or Drawing applied yet.", "error");
                }
            },
            closeCreate() {
                this.showCreateLayout = false;
                this.saveAsLayout = false;
                this.new_layout_name = "";
            },
            createNewlayout() {
                this.submitted = true;
                let _that = this;
                _that.$validator.validate().then((valid) => {
                    if (valid) {
                        var drawingList = _that.stxx.exportDrawings();
                        var studiesList = _that.stxx.exportLayout();
                        if (drawingList.length || Object.keys(studiesList.studies).length > 0) {
                            let data = {
                                id: Date.now(),
                                layout_name: _that.new_layout_name,
                                symbol: _that.loadChart,
                                mn: _that.mn,
                                description: _that.instruments[_that.loadChart].other.Description,
                                layout: {},
                                lastModify: Date.now(),
                            };
                            if (drawingList.length > 0) {
                                data.layout["drawingList"] = drawingList;
                            } // to save drawing
                            if (Object.keys(studiesList.studies).length > 0) {
                                data.layout["studiesList"] = studiesList;
                            } // to save studies

                            let totalData = _that.getRawData();
                            totalData.view_mode.chart_layout.push(data);

                            _that.layoutSelected = data.id;
                            _that._runSetRawObject({
                                globalParams: { setting: totalData },
                                localParams: { parameter: this },
                            });
                        }
                    }
                });
            },
            savelayout() {
                let _that = this;
                var drawingList = _that.stxx.exportDrawings();
                var studiesList = _that.stxx.exportLayout();
                if (drawingList.length || Object.keys(studiesList.studies).length > 0) {
                    let totalData = _that.getRawData();
                    let getIndex = totalData.view_mode.chart_layout.findIndex((item) => _that.layoutSelected == item.id);

                    if (drawingList.length > 0) {
                        // to save drawing
                        totalData.view_mode.chart_layout[getIndex].layout["drawingList"] = drawingList;
                    } else {
                        totalData.view_mode.chart_layout[getIndex].layout["drawingList"] = null;
                    }

                    if (Object.keys(studiesList.studies).length > 0) {
                        // to save studies
                        totalData.view_mode.chart_layout[getIndex].layout["studiesList"] = studiesList;
                    } else {
                        totalData.view_mode.chart_layout[getIndex].layout["studiesList"] = null;
                    }
                    totalData.view_mode.chart_layout[getIndex]["lastModify"] = Date.now();
                    this.saveCurrentlayout = true;
                    _that._runSetRawObject({
                        globalParams: { setting: totalData },
                        localParams: { parameter: this },
                    });
                }
            },
            removelayout(ID) {
                let _that = this;
                let totalData = _that.getRawData();
                totalData.view_mode.chart_layout = totalData.view_mode.chart_layout.filter((item) => item.id != ID);
                if (this.layoutSelected == ID) {
                    this.layoutSelected = "";
                }
                _that._runSetRawObject({
                    globalParams: { setting: totalData },
                    localParams: { parameter: this },
                });
            },
            selectLayout(val) {
                if (this.loadChart != val.symbol) {
                    this.layoutSelected = val.id;
                    this.$emit("input", { key: val.symbol, mn: val.mn });
                    this.ShowUploads = false;
                } else if (this.layoutSelected != val.id) {
                    this.layoutSelected = val.id;
                    this.ShowUploads = false;
                    // this.applyloadedLayout()
                }
            },
            applyloadedLayout() {
                const _that = this;
                try {
                    for (var studyInstanceName in _that.stxx.layout.studies) {
                        var sd = _that.stxx.layout.studies[studyInstanceName];
                        _.CIQ.Studies.removeStudy(_that.stxx, sd);
                    }
                } catch (e) {
                    console.log(e);
                }
                if (this.layoutSelected) {
                    let data = _that.getLayoutList().filter((item) => _that.layoutSelected == item.id)[0];
                    if (data.symbol == _that.loadChart) {
                        setTimeout(function () {
                            try {
                                if (data && data.layout) {
                                    _that.showLine = false;
                                    if (data.layout["studiesList"]) {
                                        var importOptions = {
                                            managePeriodicity: true,
                                            preserveTicksAndCandleWidth: true,
                                        };
                                        _that.stxx.importLayout(data.layout["studiesList"], importOptions);
                                        _that.chartType = data.layout["studiesList"].chartType;
                                        /*if(!data.layout.hasOwnProperty('drawing') ){
                                        _that.showLine = true;
                                    }*/
                                    }
                                    if (data.layout["drawingList"]) {
                                        // _that.stxx.reconstructDrawings(data.layout['drawingList'])
                                        _that.stxx.importDrawings(data.layout["drawingList"]);
                                        _that.stxx.changeVectorType("");
                                        let interval = setInterval(function () {
                                            if (_that.stxx.exportDrawings().length == 0) {
                                                // _that.stxx.reconstructDrawings(data.layout['drawingList'])
                                                _that.stxx.importDrawings(data.layout["drawingList"]);
                                                _that.stxx.changeVectorType("");
                                            } else {
                                                // _that.showLine = true;
                                                clearInterval(interval);
                                            }
                                        }, 100);
                                    }
                                } else {
                                    _that.changeChartType("candle");
                                    // _that.showLine = true;
                                }
                            } catch (e) {
                                console.log(e);
                            }
                        }, 300);
                    } else {
                        _that.layoutSelected = "";
                    }
                }
            },
            changeCrosshair() {
                if (this.stxx.layout.crosshair) {
                    this.stxx.layout.crosshair = true;
                } else {
                    this.stxx.layout.crosshair = false;
                }
            },
            getCrosshair() {
                try {
                    return this.stxx.layout.crosshair;
                } catch (e) {
                    return false;
                }
            }, // this.stxx.layout.crosshair = true;
            create_image() {
                var stx = this.stxx;
                var link = document.createElement("a");
                link.download = "filename.jpeg";
                link.href = stx.chart.canvas.toDataURL("image/png");
                link.click();
            },
            dismissDialog() {
                Stx.STX.DialogManager.dismissDialog();
                this.studyDialogOpened = false;
            },
            createStudy() {
                var a = Stx.STX.Studies.go(Stx.$$("studyDialog"), this.stxx);
                if (a.inputs) {
                    this.applyStudy(a, Stx.$$("studyDialog").study);
                }
                Stx.STX.DialogManager.dismissDialog();
                this.studyDialogOpened = false;
            },
            applyStudy(value, name) {
                for (var type in value) {
                    // console.log(type,'=>')
                    for (var std in value[type]) {
                        // console.log(std,':- ',value[type][std])
                        _.CIQ.Studies.studyLibrary[name][type][std] = value[type][std];
                    }
                }
                // _.CIQ.Studies.removeStudy(this.stxx, name)
                _.CIQ.Studies.addStudy(this.stxx, name); //, null, null, { display: name }
                this.studdyActive = true;
                // var params = { stx: stx, sd: sd, inputs: inputs, outputs: outputs, parameters: parameters };
            },
            addStudie(studie) {
                var _that = this;
                var study = studie;
                if (!_that.stxx || !_that.stxx.chart.dataSet) return;
                if (_.CIQ.Studies.studyLibrary[study] && _.CIQ.Studies.studyLibrary[study].name) window._.$$("studyDialog").querySelectorAll(".title")[0].innerHTML = _that.stxx.translateIf(_.CIQ.Studies.studyLibrary[study].name);
                else window._.$$("studyDialog").querySelectorAll(".title")[0].innerHTML = study.capitalize();
                Stx.STX.Studies.studyDialog(_that.stxx, study, window._.$$("studyDialog"));
                var delay = Stx.STX.ipad ? 400 : 0; // so that ipad doesn't register taps from menu selection on dialog
                setTimeout(function () {
                    Stx.STX.DialogManager.displayDialog("studyDialog");
                    _that.studyDialogOpened = true;
                }, delay);
            },
            removeStudy(val) {
                var study;
                for (var studyInstanceName in this.stxx.layout.studies) {
                    var sd = this.stxx.layout.studies[studyInstanceName];
                    if (sd.type == val) {
                        study = sd;
                    }
                }
                if (study) {
                    _.CIQ.Studies.removeStudy(this.stxx, study);
                }
                this.studiesAddedList = this.studiesAddedList.filter((data) => data != val);
            },
            ChartName(chartType) {
                return this.chartTypelist.filter((val) => val.type == chartType)[0].value; //value
            },
            ChartClass(chartType) {
                return this.chartTypelist.filter((val) => val.type == chartType)[0].class; //value
            },
            changeChartType(type) {
                this.chartType = type;
                this.stxx.setChartType(type);
            },

            drop(ev) {
                var data = ev.dataTransfer.getData("marketData");
                if (data) {
                    this.$emit("input", JSON.parse(data));
                }
            },
            enableDrawingTool: function (tool) {
                if (this.toolSelected == tool) {
                    this.toolSelected = "-";
                    this.stxx.changeVectorType("");
                } else if (tool) {
                    this.toolSelected = tool;
                    this.stxx.changeVectorType(tool);
                }
                this.stxx.currentVectorParameters.pattern = "solid";

                if (tool === "" && this.stxx.drawingObjects.length != 0) {
                    this.stxx.removeDrawing(this.stxx.drawingObjects[this.stxx.drawingObjects.length - 1]);
                    /*var numDrawings = this.stxx.drawingObjects.length; // changes as each drawing is removed
                for (var i=0; i < numDrawings; i++) {
                  this.stxx.removeDrawing(this.stxx.drawingObjects[0]);
                }
                tool = "off";*/
                }
            },
            displayError(getMessage, type) {
                this.$notify({
                    group: "foo",
                    type: type && type == null ? "error" : type,
                    text: getMessage,
                    duration: 3000,
                    closeOnClick: true,
                });
            },
            changeNewChart() {
                this.showLine = false;
                const _her = this;
                setTimeout(function () {
                    try {
                        _her.loading = 1;
                        let chartName = _her.mn ? _her.mn : _her.loadChart;
                        if (this.defaultChartInterval == "1M") {
                            this.stxx.setMaxTicks(300);
                        }
                        _her.stxx.newChart(chartName, null, null, function () {
                            _her.loading = 0;
                            // _her.applyloadedLayout()
                        });
                    } catch (e) {
                        // console.log(e)
                    }
                }, 300);
            },
            changeInterval(value) {
                if (this.defaultChartInterval != value.data) {
                    if (this.defaultChartInterval == value.data) {
                        return;
                    }
                    this.defaultChartInterval = value.data;
                    var intval = value.unit;
                    var tUnit = value.name;
                    let valInter = 1;
                    /*if (tUnit == "hour") {
                intval = 60;
                tUnit = "minute";
            }*/

                    // if (tUnit == "1W") {
                    //     intval = 7;
                    //     tUnit = "day";
                    // }

                    // if (tUnit == "1MN") {
                    //     intval = 30;
                    //     tUnit = "day";
                    // }

                    /*if (value.name == "minute") {
                this.stxx.setMaxTicks(300);
                this.ticks = 300;
            }*/

                    this.stxx.setMaxTicks(300);

                    // console.log('data',{period:1, interval:intval, timeUnit:tUnit})
                    // this.stxx.setPeriodicity({period:intval, interval:intval, timeUnit: tUnit});

                    this.stxx.setPeriodicity({ interval: intval, period: tUnit, timeUnit: tUnit });

                    // if(value.name == "minute"){
                    //     this.stxx.setPeriodicity(intval,tUnit,2,'hour');
                    // }if(value.name == "hour"){
                    //     console.log(value)
                    //     this.stxx.setPeriodicity(intval,tUnit,1,'day');
                    // }if(value.name == "day"){
                    //     this.stxx.setPeriodicity(intval,tUnit,1,'month');
                    // }else{
                    //     this.stxx.setPeriodicity({
                    //         period: intval,
                    //         interval: tUnit,
                    //         timeUnit: tUnit,
                    //     });
                    // }
                }
            },
            chartColor() {
                try {
                    let text = "",
                        grid = "";
                    // if(this.$root.$children[0].theme){
                    // if(true){
                    // text = '#ffffff'
                    // grid = '#273762'
                    // }else{
                    text = "#c7c7c7";
                    grid = "#EEEDED";
                    // }
                    this.stxx.setStyle("stx_xaxis_dark", "color", text);
                    this.stxx.setStyle("stx_xaxis", "color", text);
                    this.stxx.setStyle("stx_yaxis_dark", "color", text);
                    this.stxx.setStyle("stx_yaxis", "color", text);
                    this.stxx.setStyle("stx-panel-control", "color", text);

                    this.stxx.setStyle("stx_grid", "color", grid);
                    this.stxx.setStyle("stx_grid_dark", "color", grid);
                    //dee2e6 text
                    //29324F grid
                } catch (e) {
                    console.log(e);
                }
            },
            initalizeChart() {
                this.stxx = new CIQ.ChartEngine({
                    container: $$$(".chartContainer"),
                    layout: { candleWidth: 16, crosshair: true },
                });
                if (this.stxx.controls.chartControls) {
                    this.stxx.controls.chartControls.style.display = "none";
                }
                this.stxx.chart.xAxis.displayGridLines = true;
                this.stxx.chart.yAxis.displayGridLines = true;

                // for hollow candle
                this.stxx.setStyle("stx_hollow_candle_up", "color", "#10BD72");
                this.stxx.setStyle("stx_hollow_candle_down", "color", "#F25767");

                // for mountain
                this.stxx.setStyle("stx_mountain_chart", "border-top-color", "#10BD72");
                // for line chart
                this.stxx.setStyle("stx_line_up", "color", "#10BD72");
                this.stxx.setStyle("stx_line_down", "color", "#F25767");
                // for bar chart
                this.stxx.setStyle("stx_bar_up", "color", "#10BD72");
                this.stxx.setStyle("stx_bar_down", "color", "#F25767");
                // for base line delta chart
                this.stxx.setStyle("stx_baseline_up", "color", "#10BD72");
                this.stxx.setStyle("stx_baseline_down", "color", "#F25767");

                this.stxx.setChartType("candle");
                this.stxx.setStyle("stx_candle_up", "border-left-color", "#66000000");
                this.stxx.setStyle("stx_candle_down", "border-left-color", "#66000000");
                this.stxx.setStyle("stx_candle_up", "color", "#10BD72");
                this.stxx.setStyle("stx_candle_down", "color", "#F25767");
                this.stxx.setStyle("stx_candle_shadow_up", "color", "#10BD72");
                this.stxx.setStyle("stx_candle_shadow_down", "color", "#F25767");
                this.chartColor();

                this.stxx.chart.xAxis.axisType = "ntb";
                this.stxx.chart.yAxis.goldenRatioYAxis = true;
                this.stxx.chart.yAxis.position = "right";
                this.stxx.allowEquations = false;
                this.stxx.attachQuoteFeed(__.quoteFeedSimulator, {
                    refreshInterval: this.intervalPeriod,
                    bufferSize: 60,
                });
                this.stxx.setMarketFactory(CIQ.Market.Symbology.factory);
                this.stxx.setPeriodicity({ period: "day", interval: 1, timeUnit: "day" });
                // this.stxx.setPeriodicity(5,'minute',2,'day');

                const _her = this;
                let chartName = this.loadChart;
                this.stxx.newChart(chartName, null, null, function () {
                    CIQ.Drawing.prototype.dragToDraw = true;
                    CIQ.ChartEngine.prototype.append("draw", function () {
                        // _her.drawLine(1.3568,0)
                        // _her.drawLine(1.3568,0)
                    });
                    _her.loading = 0;
                    _her.chartReady = true;
                });
                this.stxx.setMaxTicks(70);
                // this.ticks = 300
                // CIQ.Studies.addStudy(this.stxx, "vol undr");
                this.stxx.chart.allowScrollFuture = true;
                this.stxx.chart.yAxis.width = 60;
                this.stxx.calculateYAxisPositions();
                this.stxx.draw();
                this.stxx.preferences.dragging = true;
                this.showLine = true;
                this.stxx.addEventListener("drawing", function (event) {
                    console.log(event); /*,event.detail,event.detail.tool*/
                    _her.stxx.changeVectorType(null);
                    _her.toolSelected = "-";
                });
                /*this.stxx.addEventListener("tap", function (tapObject) {
                var msg = "tap event at x: " + tapObject.x + " y: " + tapObject.y;
                var bar = this.barFromPixel(this.cx);
                if (this.chart.dataSegment[bar]) {
                    msg += " Date:" + this.chart.dataSegment[bar].DT;
                    msg += " Close:" + this.chart.dataSegment[bar].Close;
                    msg += "Price:" + this.priceFromPixel(tapObject.y)
                }
            });*/
                this.stxx.callbacks.studyOverlayEdit = function (params) {
                    var stx = params.stx;
                    var sd = params.sd;
                    STX.DialogManager.displayDialog("contextMenu");
                    if (CIQ.Studies.studyLibrary[sd.type] && CIQ.Studies.studyLibrary[sd.type].name) {
                        $$("contextMenu").querySelectorAll(".title")[0].innerHTML = _her.stxx.translateIf(CIQ.Studies.studyLibrary[sd.type].name);
                    } else {
                        $$("contextMenu").querySelectorAll(".title")[0].innerHTML = sd.type.capitalize();
                    }
                    if (CIQ.Studies.studyLibrary[sd.type] && CIQ.Studies.studyLibrary[sd.type].name) {
                        $$("studyDialog").querySelectorAll(".title")[0].innerHTML = _her.stxx.translateIf(CIQ.Studies.studyLibrary[sd.type].name);
                    } else {
                        $$("studyDialog").querySelectorAll(".title")[0].innerHTML = sd.type.capitalize();
                    }
                    // var edit=Stx.$$$(".stx-edit", Stx.$$("contextMenu"));
                    var remove = $$$(".stx-delete", $$("contextMenu"));
                    /*STX.safeClickTouch(edit, function(){
                      STX.DialogManager.dismissDialog();
                      STX.Studies.studyDialog(stx, sd.type, Stx.$$("studyDialog"), params);
                      STX.DialogManager.displayDialog("studyDialog");
                  });*/
                    STX.safeClickTouch(remove, function () {
                        STX.DialogManager.dismissDialog();
                        STX.Studies.removeStudy(stx, sd);
                        /*var closeIcon=document.querySelectorAll("#menuWrapperStudies #" + sd.type.replace(" ","-"));
                        for(var c=0;closeIcon && c<closeIcon.length;c++){
                            STX.clearSafeClickTouches(closeIcon[c]);
                            closeIcon[c].style.display="";
              }*/
                    });
                    // STX.DialogManager.displayDialog("contextMenu");
                };

                this.stxx.prepend("grabHandle", function () {
                    return true;
                });
                /*this.stxx.callbacks.studyPanelEdit = function (params) {
                var stx=params.stx;
                var sd=params.sd;
                if(CIQ.Studies.studyLibrary[sd.type] && CIQ.Studies.studyLibrary[sd.type].name){
                    Stx.$$("studyDialog").querySelectorAll(".title")[0].innerHTML=_her.stxx.translateIf(CIQ.Studies.studyLibrary[sd.type].name);
                }else{
                    Stx.$$("studyDialog").querySelectorAll(".title")[0].innerHTML=sd.type.capitalize();
                }
                STX.Studies.studyDialog(stx, sd.type, Stx.$$("studyDialog"), params);
                STX.DialogManager.displayDialog("studyDialog");
            }*/
                this.applyloadedLayout();
            },
            setChartValues(open, high, low, close) {
                this.ChartValues.Open = parseFloat(parseFloat(open || 0).toFixed(5));
                this.ChartValues.High = parseFloat(parseFloat(high || 0).toFixed(5));
                this.ChartValues.Low = parseFloat(parseFloat(low || 0).toFixed(5));
                this.ChartValues.Close = parseFloat(parseFloat(close || 0).toFixed(5));
            },
            drawLine(price, side, pattern) {
                const _her = this;
                const parameters = {
                    pattern: pattern, // options: "solid","dashed","dotted"
                    lineWidth: 0.4, // select any with for the line
                };
                const x = _her.stxx.pixelFromDate(_her.stxx.chart.dataSet[_her.stxx.chart.dataSet.length - 1].Date, _her.stxx.chart);
                const y = _her.stxx.pixelFromPrice(price, _her.stxx.chart.panel, _her.stxx.chart.yAxis);

                const color = parseInt(side) == 0 ? "red" : "green"; // select any color
                const type = "horizontal"; // don't change this setting since we are interested in drawing a horizontal line only with a _her on the y-axis
                _her.stxx.plotLine(x, x + 1, y, y, color, type, _her.stxx.chart.context, true, parameters);
            },
            scrollUp() {
                /*$('#studytools').scrollTop($('#studytools').scrollTop() - 40)*/
            },
            scrollDown() {
                // console.log('scrollTop',$('#studytools')[0].scrollHeight - $('#studytools').outerHeight())
                // $('#studytools').scrollTop($('#studytools')[0].scrollHeight - $('#studytools').outerHeight())
                /*$('#studytools').scrollTop($('#studytools').scrollTop() + 40)*/
            },
        },
        computed: {
            weekprogressChart() {
                if (Object.keys(this.store.trendingIsins).length && this.loadChart && this.store.trendingIsins[this.loadChart]) {
                    let weeklydata = JSON.parse(this.store.trendingIsins[this.loadChart].weekly);
                    if (Object.keys(weeklydata).length) {
                        if (weeklydata.low && weeklydata.high && this.ChartValues.Close && this.instruments[this.loadChart] && this.instruments[this.loadChart].B && this.ChartValues.High && this.ChartValues.Low) {
                            let width = 0,
                                margin = 0;
                            if (this.instruments[this.loadChart].B > weeklydata.high) {
                                //cuurent value greater than high
                                weeklydata.high = this.instruments[this.loadChart].B;
                            } else if (this.instruments[this.loadChart].B < weeklydata.low) {
                                //cuurent value lesser than low
                                weeklydata.low = this.instruments[this.loadChart].B;
                            }
                            if (parseFloat(weeklydata.high) < parseFloat(this.ChartValues.High)) {
                                //weekly high lesser than today High
                                weeklydata.high = this.ChartValues.High;
                            }
                            if (parseFloat(this.ChartValues.Low) < parseFloat(weeklydata.low)) {
                                weeklydata.low = this.ChartValues.Low;
                            }
                            if (weeklydata.open >= parseFloat(this.instruments[this.loadChart].B)) {
                                //current < previous close
                                if (parseFloat(this.instruments[this.loadChart].B) > 0) {
                                    //current value positive
                                    width = ((weeklydata.open - parseFloat(this.instruments[this.loadChart].B)) / (weeklydata.high - weeklydata.low)) * 100;
                                    margin = ((weeklydata.open - weeklydata.low) / (weeklydata.high - weeklydata.low)) * 100;
                                    margin = 100 - margin;
                                    if (parseInt(moment().day()) == 1) {
                                        //Monday
                                        weeklydata.low = this.ChartValues.Low;
                                        weeklydata.high = this.ChartValues.High;
                                    }
                                    // let percentLow = parseFloat(weeklydata.low) + (parseFloat((weeklydata.low)*0.5/100))
                                    // let percentHigh = parseFloat(weeklydata.high) - (parseFloat((weeklydata.high)*0.5/100))
                                    if (parseFloat(this.instruments[this.loadChart].B) == parseFloat(weeklydata.low)) {
                                        return {
                                            progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;right:${parseFloat(margin).toFixed(
                                                4
                                            )}%;position: absolute; background: linear-gradient(to right,  #d04f21 0%, #f7931a 100%);`,
                                            icon: `position: absolute;left:-5px;color:orange`,
                                            class: `Orange`,
                                            low: true,
                                            high: false,
                                        }; //right :${100 - (margin - width)}%
                                    }
                                    if (parseFloat(this.instruments[this.loadChart].B) == parseFloat(weeklydata.high)) {
                                        return {
                                            progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;right:${parseFloat(margin).toFixed(
                                                4
                                            )}%;position: absolute; background: linear-gradient(to right,  #d04f21 0%, #f7931a 100%);`,
                                            icon: `position: absolute;left:-5px;color:orange`,
                                            class: `Orange`,
                                            low: false,
                                            high: true,
                                        }; //right :${100 - (margin - width)}%
                                    }
                                    if (!(parseFloat(this.instruments[this.loadChart].B) == parseFloat(weeklydata.low)) && !(parseFloat(this.instruments[this.loadChart].B) == parseFloat(weeklydata.high))) {
                                        return {
                                            progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;right:${parseFloat(margin).toFixed(
                                                4
                                            )}%;position: absolute; background: linear-gradient(to right,  #d04f21 0%, #f7931a 100%);`,
                                            icon: `position: absolute;left:-5px;color:orange`,
                                            class: `Orange`,
                                            low: false,
                                            high: false,
                                        }; //right :${100 - (margin - width)}%
                                    }
                                }
                            } else {
                                width = ((parseFloat(this.instruments[this.loadChart].B) - weeklydata.open) / (weeklydata.high - weeklydata.low)) * 100;
                                margin = ((weeklydata.open - weeklydata.low) / (weeklydata.high - weeklydata.low)) * 100;
                                if (parseInt(moment().day()) == 1) {
                                    //Monday
                                    weeklydata.low = this.ChartValues.Low;
                                    weeklydata.high = this.ChartValues.High;
                                }
                                // let percentLow = parseFloat(weeklydata.low) + (parseFloat((weeklydata.low)*0.5/100))
                                // let percentHigh = parseFloat(weeklydata.high) - (parseFloat((weeklydata.high)*0.5/100))
                                if (parseFloat(this.instruments[this.loadChart].B) == parseFloat(weeklydata.low)) {
                                    return {
                                        progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;left:${parseFloat(margin).toFixed(4)}%;position: absolute; background: linear-gradient(90deg, #38EF7D 0%, #11998E 100%);`,
                                        icon: `position: absolute;right:-5px;color:green`,
                                        class: `Green`,
                                        low: true,
                                        high: false,
                                    };
                                }
                                if (parseFloat(this.instruments[this.loadChart].B) == parseFloat(weeklydata.high)) {
                                    return {
                                        progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;left:${parseFloat(margin).toFixed(4)}%;position: absolute; background: linear-gradient(90deg, #38EF7D 0%, #11998E 100%);`,
                                        icon: `position: absolute;right:-5px;color:green`,
                                        class: `Green`,
                                        low: false,
                                        high: true,
                                    };
                                }
                                if (!(parseFloat(this.instruments[this.loadChart].B) == parseFloat(weeklydata.low)) && !(parseFloat(this.instruments[this.loadChart].B) == parseFloat(weeklydata.high))) {
                                    return {
                                        progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;left:${parseFloat(margin).toFixed(4)}%;position: absolute; background: linear-gradient(90deg, #38EF7D 0%, #11998E 100%);`,
                                        icon: `position: absolute;right:-5px;color:green`,
                                        class: `Green`,
                                        low: false,
                                        high: false,
                                    };
                                }
                            }
                        } else {
                            return { progressBar: "", icon: "", class: "", low: false, high: false };
                        }
                    } else {
                        return { progressBar: "", icon: "", class: "", low: false, high: false };
                    }
                } else {
                    return { progressBar: "", icon: "", class: "", low: false, high: false };
                }
            },
            monthprogressChart() {
                if (Object.keys(this.store.trendingIsins).length && this.loadChart && this.store.trendingIsins[this.loadChart]) {
                    let monthlydata = JSON.parse(this.store.trendingIsins[this.loadChart].monthly);
                    if (Object.keys(monthlydata).length) {
                        if (monthlydata.low && monthlydata.high && monthlydata.open && this.instruments[this.loadChart] && this.instruments[this.loadChart].B && this.ChartValues.High && this.ChartValues.Low) {
                            let width = 0,
                                margin = 0;
                            if (this.instruments[this.loadChart].B > monthlydata.high) {
                                //cuurent value greater than high
                                monthlydata.high = this.instruments[this.loadChart].B;
                            } else if (this.instruments[this.loadChart].B < monthlydata.low) {
                                //cuurent value lesser than low
                                monthlydata.low = this.instruments[this.loadChart].B;
                            }
                            if (parseFloat(monthlydata.high) < parseFloat(this.ChartValues.High)) {
                                //monthly high lesser than today High
                                monthlydata.high = this.ChartValues.High;
                            }
                            if (parseFloat(this.ChartValues.Low) < parseFloat(monthlydata.low)) {
                                monthlydata.low = this.ChartValues.Low;
                            }
                            if (monthlydata.open >= parseFloat(this.instruments[this.loadChart].B)) {
                                //current < previous close
                                if (parseFloat(this.instruments[this.loadChart].B) > 0) {
                                    //current value positive
                                    width = ((monthlydata.open - parseFloat(this.instruments[this.loadChart].B)) / (monthlydata.high - monthlydata.low)) * 100;
                                    margin = ((monthlydata.open - monthlydata.low) / (monthlydata.high - monthlydata.low)) * 100;
                                    margin = 100 - margin;
                                    if (moment().startOf("month").format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
                                        monthlydata.low = this.ChartValues.Low;
                                        monthlydata.high = this.ChartValues.High;
                                    }
                                    // let percentLow = parseFloat(monthlydata.low) + (parseFloat((monthlydata.low)*0.5/100))
                                    // let percentHigh = parseFloat(monthlydata.high) - (parseFloat((monthlydata.high)*0.5/100))
                                    if (parseFloat(this.instruments[this.loadChart].B) == parseFloat(monthlydata.low)) {
                                        return {
                                            progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;right:${parseFloat(margin).toFixed(
                                                4
                                            )}%;position: absolute; background: linear-gradient(to right,   #d04f21 0%, #f7931a 100%);`,
                                            icon: `position: absolute;left:-5px;color:orange`,
                                            class: `Orange`,
                                            low: true,
                                            high: false,
                                        }; //right :${100 - (margin - width)}%
                                    }
                                    if (parseFloat(this.instruments[this.loadChart].B) == parseFloat(monthlydata.high)) {
                                        return {
                                            progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;right:${parseFloat(margin).toFixed(
                                                4
                                            )}%;position: absolute; background: linear-gradient(to right,   #d04f21 0%, #f7931a 100%);`,
                                            icon: `position: absolute;left:-5px;color:orange`,
                                            class: `Orange`,
                                            low: false,
                                            high: true,
                                        }; //right :${100 - (margin - width)}%
                                    }
                                    if (!(parseFloat(this.instruments[this.loadChart].B) == parseFloat(monthlydata.low)) && !(parseFloat(this.instruments[this.loadChart].B) == parseFloat(monthlydata.high))) {
                                        return {
                                            progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;right:${parseFloat(margin).toFixed(
                                                4
                                            )}%;position: absolute; background: linear-gradient(to right,   #d04f21 0%, #f7931a 100%);`,
                                            icon: `position: absolute;left:-5px;color:orange`,
                                            class: `Orange`,
                                            low: false,
                                            high: false,
                                        }; //right :${100 - (margin - width)}%
                                    }
                                }
                            } else {
                                width = ((parseFloat(this.instruments[this.loadChart].B) - monthlydata.open) / (monthlydata.high - monthlydata.low)) * 100;
                                margin = ((monthlydata.open - monthlydata.low) / (monthlydata.high - monthlydata.low)) * 100;
                                // let percentLow = parseFloat(monthlydata.low) + (parseFloat((monthlydata.low)*0.5/100))
                                // let percentHigh = parseFloat(monthlydata.high) - (parseFloat((monthlydata.high)*0.5/100))
                                if (parseFloat(this.instruments[this.loadChart].B) == parseFloat(monthlydata.low)) {
                                    return {
                                        progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;left:${parseFloat(margin).toFixed(4)}%;position: absolute; background: linear-gradient(90deg, #38EF7D 0%, #11998E 100%);`,
                                        icon: `position: absolute;right:-5px;color:green`,
                                        class: `Green`,
                                        low: true,
                                        high: false,
                                    };
                                }
                                if (parseFloat(this.instruments[this.loadChart].B) == parseFloat(monthlydata.high)) {
                                    return {
                                        progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;left:${parseFloat(margin).toFixed(4)}%;position: absolute; background: linear-gradient(90deg, #38EF7D 0%, #11998E 100%);`,
                                        icon: `position: absolute;right:-5px;color:green`,
                                        class: `Green`,
                                        low: false,
                                        high: true,
                                    };
                                }
                                if (!(parseFloat(this.instruments[this.loadChart].B) == parseFloat(monthlydata.low)) && !(parseFloat(this.instruments[this.loadChart].B) == parseFloat(monthlydata.high))) {
                                    return {
                                        progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;left:${parseFloat(margin).toFixed(4)}%;position: absolute; background: linear-gradient(90deg, #38EF7D 0%, #11998E 100%);`,
                                        icon: `position: absolute;right:-5px;color:green`,
                                        class: `Green`,
                                        low: false,
                                        high: false,
                                    };
                                }
                            }
                        } else {
                            return { progressBar: "", icon: "", class: "", low: false, high: false };
                        }
                    } else {
                        return { progressBar: "", icon: "", class: "", low: false, high: false };
                    }
                } else {
                    return { progressBar: "", icon: "", class: "", low: false, high: false };
                }
            },
            yearprogressChart() {
                if (Object.keys(this.store.trendingIsins).length && this.loadChart && this.store.trendingIsins[this.loadChart]) {
                    let yearlydata = JSON.parse(this.store.trendingIsins[this.loadChart].yearly);
                    if (Object.keys(yearlydata).length) {
                        if (yearlydata.low && yearlydata.high && yearlydata.open && this.instruments[this.loadChart] && this.instruments[this.loadChart].B && this.ChartValues.High && this.ChartValues.Low) {
                            let width = 0,
                                margin = 0;
                            if (this.instruments[this.loadChart].B > yearlydata.high) {
                                //cuurent value greater than high
                                yearlydata.high = this.instruments[this.loadChart].B;
                            } else if (this.instruments[this.loadChart].B < yearlydata.low) {
                                //cuurent value lesser than low
                                yearlydata.low = this.instruments[this.loadChart].B;
                            }
                            if (parseFloat(yearlydata.high) < parseFloat(this.ChartValues.High)) {
                                //yeaerly high lesser than today High
                                yearlydata.high = this.ChartValues.High;
                            }

                            if (parseFloat(this.ChartValues.Low) < parseFloat(yearlydata.low)) {
                                yearlydata.low = this.ChartValues.Low;
                            }
                            if (yearlydata.open >= parseFloat(this.instruments[this.loadChart].B)) {
                                //current < previous close
                                if (parseFloat(this.instruments[this.loadChart].B) > 0) {
                                    //current value positive
                                    width = ((yearlydata.open - parseFloat(this.instruments[this.loadChart].B)) / (yearlydata.high - yearlydata.low)) * 100;
                                    margin = ((yearlydata.open - yearlydata.low) / (yearlydata.high - yearlydata.low)) * 100;
                                    margin = 100 - margin;
                                    // let percentLow = parseFloat(yearlydata.low) + (parseFloat((yearlydata.low)*0.5/100))
                                    // let percentHigh = parseFloat(yearlydata.high) - (parseFloat((yearlydata.high)*0.5/100))
                                    if (parseFloat(this.instruments[this.loadChart].B) == parseFloat(yearlydata.low)) {
                                        return {
                                            progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;right:${parseFloat(margin).toFixed(
                                                4
                                            )}%;position: absolute; background: linear-gradient(to right,   #d04f21 0%, #f7931a 100%);`,
                                            icon: `position: absolute;left:-5px;color:orange`,
                                            class: `Orange`,
                                            low: true,
                                            high: false,
                                        }; //right :${100 - (margin - width)}%
                                    }
                                    if (parseFloat(this.instruments[this.loadChart].B) == parseFloat(yearlydata.high)) {
                                        return {
                                            progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;right:${parseFloat(margin).toFixed(
                                                4
                                            )}%;position: absolute; background: linear-gradient(to right,   #d04f21 0%, #f7931a 100%);`,
                                            icon: `position: absolute;left:-5px;color:orange`,
                                            class: `Orange`,
                                            low: false,
                                            high: true,
                                        }; //right :${100 - (margin - width)}%
                                    }
                                    if (!(parseFloat(this.instruments[this.loadChart].B) == parseFloat(yearlydata.low)) && !(parseFloat(this.instruments[this.loadChart].B) == parseFloat(yearlydata.high))) {
                                        return {
                                            progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;right:${parseFloat(margin).toFixed(
                                                4
                                            )}%;position: absolute; background: linear-gradient(to right,  #d04f21 0%, #f7931a 100%);`,
                                            icon: `position: absolute;left:-5px;color:orange`,
                                            class: `Orange`,
                                            low: false,
                                            high: false,
                                        }; //right :${100 - (margin - width)}%
                                    }
                                }
                            } else {
                                width = ((parseFloat(this.instruments[this.loadChart].B) - yearlydata.open) / (yearlydata.high - yearlydata.low)) * 100;
                                margin = ((yearlydata.open - yearlydata.low) / (yearlydata.high - yearlydata.low)) * 100;
                                if (parseFloat(this.instruments[this.loadChart].B) == parseFloat(yearlydata.low)) {
                                    return {
                                        progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;left:${parseFloat(margin).toFixed(4)}%;position: absolute; background: linear-gradient(90deg, #38EF7D 0%, #11998E 100%);`,
                                        icon: `position: absolute;right:-5px;color:green`,
                                        class: `Green`,
                                        low: true,
                                        high: false,
                                    };
                                }
                                if (parseFloat(this.instruments[this.loadChart].B) == parseFloat(yearlydata.high)) {
                                    return {
                                        progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;left:${parseFloat(margin).toFixed(4)}%;position: absolute; background: linear-gradient(90deg, #38EF7D 0%, #11998E 100%);`,
                                        icon: `position: absolute;right:-5px;color:green`,
                                        class: `Green`,
                                        low: false,
                                        high: true,
                                    };
                                }
                                if (!(parseFloat(this.instruments[this.loadChart].B) == parseFloat(yearlydata.low)) && !(parseFloat(this.instruments[this.loadChart].B) == parseFloat(yearlydata.high))) {
                                    return {
                                        progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;left:${parseFloat(margin).toFixed(4)}%;position: absolute; background: linear-gradient(90deg, #38EF7D 0%, #11998E 100%);`,
                                        icon: `position: absolute;right:-5px;color:green`,
                                        class: `Green`,
                                        low: false,
                                        high: false,
                                    };
                                }
                            }
                        } else {
                            return { progressBar: "", icon: "", class: "", low: false, high: false };
                        }
                    } else {
                        return { progressBar: "", icon: "", class: "", low: false, high: false };
                    }
                } else {
                    return { progressBar: "", icon: "", class: "", low: false, high: false };
                }
            },
            getProgressRange() {
                if (this.loadChart != "" && this.instruments[this.loadChart]) {
                    if (this.ChartValues.Low && this.ChartValues.High && this.ChartValues.Close && this.instruments[this.loadChart] && this.instruments[this.loadChart].B) {
                        let width = 0,
                            margin = 0;
                        if (this.instruments[this.loadChart].B > this.ChartValues.High) {
                            //cuurent value greater than high
                            this.ChartValues.High = this.instruments[this.loadChart].B;
                        } else if (this.instruments[this.loadChart].B < this.ChartValues.Low) {
                            //cuurent value lesser than low
                            this.ChartValues.Low = this.instruments[this.loadChart].B;
                        }
                        if (this.ChartValues.Close >= parseFloat(this.instruments[this.loadChart].B)) {
                            //current < previous close
                            if (parseFloat(this.instruments[this.loadChart].B) > 0) {
                                //current value positive
                                width = ((this.ChartValues.Close - parseFloat(this.instruments[this.loadChart].B)) / (this.ChartValues.High - this.ChartValues.Low)) * 100;
                                margin = ((this.ChartValues.Close - this.ChartValues.Low) / (this.ChartValues.High - this.ChartValues.Low)) * 100;
                                margin = 100 - margin;
                            }
                            return {
                                progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;right:${parseFloat(margin).toFixed(4)}%;position: absolute; background: linear-gradient(to right,  #d04f21 0%, #f7931a 100%);`,
                                icon: `position: absolute;left:-5px;color:orange`,
                                class: `Orange`,
                            }; //right :${100 - (margin - width)}%
                        } else {
                            width = ((parseFloat(this.instruments[this.loadChart].B) - this.ChartValues.Close) / (this.ChartValues.High - this.ChartValues.Low)) * 100;
                            margin = ((this.ChartValues.Close - this.ChartValues.Low) / (this.ChartValues.High - this.ChartValues.Low)) * 100;
                            return {
                                progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;left:${parseFloat(margin).toFixed(4)}%;position: absolute; background: linear-gradient(90deg, #38EF7D 0%, #11998E 100%);`,
                                icon: `position: absolute;right:-5px;color:green`,
                                class: `Green`,
                            };
                        }
                    } else {
                        return { progressBar: "", icon: "", class: "" };
                    }
                } else {
                    return { progressBar: "", icon: "", class: "" };
                }
            },
            checkFev() {
                try {
                    let fevList = [];
                    let _that = this;
                    this.watchListData.forEach((val) => {
                        if (val._id === _that.watchId) {
                            fevList = val.is_fav ? val.is_fav : [];
                        }
                    });
                    return fevList.includes(this.loadChart);
                } catch (e) {
                    return false;
                }
            },
            /*setLine(){
            let _that = this
            if(this.showLine){
                if(_that.openTradeList.length > 0){
                    try{
                        _.CIQ.ChartEngine.prototype.append("draw", function(){
                            _that.openTradeList.forEach((val,index) =>     {
                                if(val.Symbol.slice(0,6) == _that.loadChart){
                                    _that.drawLine(val.Price, val.Side, 'solid') // "solid","dashed","dotted"
                                }
                                if(index == _that.openTradeList.length-1){
                                    // _that.chartReady = false
                                    // clearInterval(intervalData);
                                }
                            })
                        })
                    }catch(e){}
                }
                if(_that.pendingOrderList.length > 0){
                    try{
                        _.CIQ.ChartEngine.prototype.append("draw", function(){
                            _that.pendingOrderList.forEach((val,index) =>     {
                                if(val.Type=='EL' || val.Type=='ES' && val.Symbol.slice(0,6) == _that.loadChart){
                                    _that.drawLine(val.Price, val.Side, 'dashed') // "solid","dashed","dotted"
                                }
                                if(index == _that.openTradeList.length-1){
                                    // console.log('this.chartReady ========> closed',_that.chartReady)
                                    // _that.chartReady = false
                                    // clearInterval(intervalData1);
                                    // console.log('intervalData closed')
                                }
                            })
                        })
                    }catch(e){}
                }
            }
        },*/
            studyListfunc() {
                try {
                    var _that = this;
                    var a = {},
                        b = Object.keys(window._.CIQ.Studies.studyLibrary).sort();
                    b.forEach((key) => {
                        if (_that.searchStudy != "") {
                            if (_.CIQ.Studies.studyLibrary[key].name.toLowerCase().includes(_that.searchStudy.toLowerCase())) {
                                a[key] = _.CIQ.Studies.studyLibrary[key].name;
                            }
                        } else {
                            a[key] = _.CIQ.Studies.studyLibrary[key].name;
                        }
                    });
                    // console.log(a)
                    return a;
                } catch (e) {
                    return {};
                }
            },
            /*candleChangeByWS(){
            try{
                this.stxx.chart.dataSet[this.stxx.chart.dataSet.length-1].High = this.instruments[this.loadChart].B
                let open  = this.stxx.chart.dataSet[this.stxx.chart.dataSet.length-1].Close                    
                let high  = this.stxx.chart.dataSet[this.stxx.chart.dataSet.length-1].High
                let low    = this.stxx.chart.dataSet[this.stxx.chart.dataSet.length-1].Low
                let Volume    = this.stxx.chart.dataSet[this.stxx.chart.dataSet.length-1].Volume                    
                let close =  this.instruments[this.loadChart].B
                this.setChartValues(open,high,low,close) 
                document.title = this.loadChart+" "+LTP +" "+this.formatfloat(parseFloat((((this.instruments[this.loadChart].B - this.instruments[this.loadChart].other.closepx)/this.instruments[this.loadChart].other.closepx)*100).toFixed(3)))+"%"
            }catch(e){}
        },*/
            lastData() {
                try {
                    if (this.stxx.chart && this.stxx.chart.dataSet && Array.isArray(this.stxx.chart.dataSet) && this.stxx.chart.dataSet.length > 0) {
                        return new Date(this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].DT);
                    } else {
                        return new Date();
                    }
                } catch (e) {
                    return new Date();
                }
            },
            create_liveCandle() {
                try {
                    if (this.loadChart != "" && this.instruments[this.loadChart]) {
                        let newQuotes = {};
                        var d = new Date(); // DT is a string in ISO format, make it a Date instance

                        var getYear = d.getFullYear();
                        var getDate = d.getDate();
                        var getMonth = d.getMonth() + 1;
                        var getHours = d.getHours();
                        var getMins = d.getMinutes();

                        if (getMonth <= 9) {
                            getMonth = "0" + getMonth;
                        }
                        if (getDate <= 9) {
                            getDate = "0" + getDate;
                        }
                        if (getHours.toString().length <= 1) {
                            getHours = "0" + getHours;
                        }
                        if (getMins.toString().length <= 1) {
                            getMins = "0" + getMins;
                        }

                        let CurrentCandleDT = "";
                        if (this.defaultChartInterval == "1D" || this.defaultChartInterval == "1W" || this.defaultChartInterval == "1MN") {
                            CurrentCandleDT = getYear + "-" + getMonth + "-" + getDate + " " + "00:00:00";
                        } else {
                            CurrentCandleDT = getYear + "-" + getMonth + "-" + getDate + " " + getHours + ":" + getMins + ":00";
                        }

                        let LTP = this.instruments[this.loadChart].B;
                        document.title =
                            this.loadChart +
                            " " +
                            LTP +
                            " " +
                            this.formatfloat(parseFloat((((this.instruments[this.loadChart].B - this.instruments[this.loadChart].other.closepx) / this.instruments[this.loadChart].other.closepx) * 100).toFixed(3))) +
                            "%";

                        // Previous 1 min

                        // console.log(LTP, "LTP =>", this.stxx.chart.dataSet)

                        // const da = new Date();
                        // const seconds = da.getSeconds();

                        let open = this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].Close;
                        let high = this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].High;
                        let low = this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].Low;
                        // let Volume    = this.stxx.chart.dataSet[this.stxx.chart.dataSet.length-1].Volume
                        let close = this.instruments[this.loadChart].B;
                        newQuotes.DT = this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].DT;
                        newQuotes.Date = this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].Date;
                        //console.log('----->',this.stxx.chart.dataSet[this.stxx.chart.dataSet.length-2])
                        this.setChartValues(
                            this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].Open,
                            this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].High,
                            this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].Low,
                            this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 2].Close
                        );

                        if (low != 0 && !isNaN(low)) {
                            if (this.defaultChartInterval == "1M" && new Date(CurrentCandleDT).getTime() !== new Date(this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].DT).getTime()) {
                                open = this.instruments[this.loadChart].B;
                                high = this.instruments[this.loadChart].B;
                                low = this.instruments[this.loadChart].B;
                                close = this.instruments[this.loadChart].B;

                                newQuotes.Open = parseFloat(open);
                                newQuotes.High = parseFloat(high);
                                newQuotes.Low = parseFloat(low);
                                newQuotes.Close = parseFloat(close);
                                newQuotes.Volume = parseFloat(0);
                                newQuotes.Bid = parseFloat(this.instruments[this.loadChart].B);
                                newQuotes.DT = new Date(CurrentCandleDT);
                                newQuotes.Date = new Date(CurrentCandleDT);

                                this.stxx.appendMasterData(newQuotes, null, { fillGaps: true });
                            } else if (this.defaultChartInterval == "1D" && new Date(CurrentCandleDT).getTime() !== new Date(this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].DT).getTime()) {
                                open = this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].Close;
                                high = this.instruments[this.loadChart].HIGH;
                                low = this.instruments[this.loadChart].LOW;
                                close = this.stxx.chart.dataSet[this.stxx.chart.dataSet.length - 1].Close;
                                newQuotes.DT = new Date(CurrentCandleDT);
                                newQuotes.Date = new Date(CurrentCandleDT);
                                this.stxx.appendMasterData(newQuotes, null, { fillGaps: true });
                            } else {
                                newQuotes.Open = parseFloat(open);
                                newQuotes.High = parseFloat(high);
                                newQuotes.Low = parseFloat(low);
                                newQuotes.Close = parseFloat(close);
                                newQuotes.Volume = parseFloat(0);
                                newQuotes.Bid = parseFloat(this.instruments[this.loadChart].B);

                                // const d = new Date();
                                // let minutes = d.getMinutes();

                                newQuotes.DT = new Date(CurrentCandleDT);
                                newQuotes.Date = new Date(CurrentCandleDT);
                                this.stxx.appendMasterData(newQuotes, null, { fillGaps: true });
                            }

                            //this.stxx.updateChartData(newQuotes, null, {fillGaps: true});
                            // console.log( "newQuotes =>",newQuotes,this.stxx.chart.dataSet[this.stxx.chart.dataSet.length-1])
                            return this.instruments[this.loadChart].B;
                        }
                    }
                } catch (ex) {
                    console.log("ex in charting app", ex);
                    return "";
                }
                return "";
            },
            instruments() {
                try {
                    this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
                    return this.$store.getters[Const.GET_PROVIDERS]["priceFeed"].iCache.asKeysValueArrayAll();
                } catch (ex) {
                    return [];
                }
            },
            get_currencies() {
                try {
                    this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
                    return this.$store.getters[Const.GET_PROVIDERS]["priceFeed"].iCache.asKeysValueArrayAll();
                } catch (ex) {
                    // console.log('OO-one [ex]', ex)
                    return [];
                }
            },
        },
        created() {
            const _that = this;
            /*if(localStorage.getItem('watchId')){
            let watchId = localStorage.getItem('watchId');
            this.watchId = (watchId == 'null')?null:watchId
        }else{
            this.watchId = null
        }*/
            setTimeout(function () {
                _that.initalizeChart();
            }, 1000);
            // setTimeout(function(){ _that.drawLine() }, 5000);
        },
        mounted() {
            // addEvent(window, "resize", function(event) {
            //   console.log('resized');
            // });
            /*const _that = this
        $( window ).resize(function() {
            var elem = $('#studytools');
            if(elem[0].scrollHeight-2 <= elem.outerHeight()){
                _that.upButton =false;
                _that.downButton =false;
            }
            if(elem.scrollTop() == 0){
                _that.upButton =false
            }else { _that.upButton = true }
            if((elem.scrollTop() + elem.outerHeight()) >= (elem[0].scrollHeight-1)){
                _that.downButton = false
            }else{ _that.downButton = true}
        })
        if($('#studytools')[0].scrollHeight-2 <= $('#studytools').outerHeight()){
            _that.upButton =false
            _that.downButton =false
        }
        $('#studytools').on('scroll', (e)=>{
            var elem = $(e.currentTarget);
            if(elem[0].scrollHeight-2 <=elem.outerHeight()){
                _that.upButton =false;_that.downButton =false;
            }
            if(elem.scrollTop() == 0){
                _that.upButton =false
            }else { _that.upButton = true }
            if((elem.scrollTop() + elem.outerHeight()) >= (elem[0].scrollHeight-1)){
                _that.downButton = false
            }else{ _that.downButton = true}
        });
        $(document).on("click", function(event){
            if(!$(event.target).closest('.searchPopUp').length){
                _that.searchPopUp = false
            }
            if(!$(event.target).closest('.ShowSearchPopup').length){
                _that.ShowSearchPopup = false
            }
            if(!$(event.target).closest('#studyDialog').length && !$(event.target).closest('#contextMenu').length && !$(event.target).closest('.ciqColorPicker').length && _that.studyDialogOpened){
                _that.dismissDialog()
                _that.studyDialogOpened = false
            }
        });*/
            document.querySelector(".full-scr").addEventListener("fullscreenchange", (event) => {
                if (document.fullscreenElement) {
                    event.target.classList.add("fullScreen-map");
                } else {
                    event.target.classList.remove("fullScreen-map");
                }
            });
        },
    };
</script>
<style scoped>
    @import "/assets/css/chartiq.css";
    @import "/assets/css/stx-chart.css";
    @import "/assets/css/stx-standard.css";
</style>
<style>
    .col-md-9.leftArea-chart.full-scr.fullScreen-map {
        background-color: #ffff;
    }
    .chart-title {
        display: none;
    }
</style>

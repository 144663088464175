<template>
  <div class="row">
    <div class="col-12 col-lg-12 mb-4 mb-lg-0">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <h3 class="mb-0">Technical Analysis</h3>
        <router-link
          to="/market-insights"
          class="green_btn d-flex align-items-center"
          >See All
          <vue-feather
            size="12px"
            type="chevron-right"
            class="chevron-right"
          ></vue-feather
        ></router-link>
      </div>
      <div v-if="store.financialContent.totalRecords && store.financialContent.totalRecords > 0">
        <div
          class="d-flex mb-4"
          v-for="(item, key) in store.financialContent.records.slice(0, 4)"
          :key="key"
        >
          <div class="flex-shrink-0">
            <img
              width="150"
              height="100"
              class="image"
              :src="item.image_url"
              alt="Image"
            />
          </div>
          <div class="flex-grow-1 ms-3">
            <h6
              class="medium"
              v-if="item.metadata && item.metadata.header"
            >
              <a class="d-block" href="javascript:void(0)" @click="() => goToDetailPage(item)">
                {{(item.source != 'FXS') ? item.metadata.header.slice(0, 40) : item.title}}...</a
              >
            </h6>
            <p class="clock f-12 mb-2">
              <i data-feather="clock" class="clock-icon"></i>
              {{formatNow(item.created,'now',0)}}
            </p>
            <p v-html="item.text.slice(0, 150) + '...'"></p>
          </div>
        </div>
      </div>
      <no-record v-else></no-record>
    </div>
    <!-- div class="col-12 col-lg-6">
      <h3 class="mb-3">Top Authors</h3>
      <div class="table-responsive">
        <table class="table lineTable table-sm m-0">
          <thead>
            <tr class="medium border-top-0">
              <th>Name</th>
              <th>Post</th>
              <th>Followers</th>
              <th align="right">Activity</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span class="d-flex align-items-center">
                  <vue-feather class="userIcon me-2" type="user"></vue-feather>
                  <span class="name">Abc</span>
                </span>
              </td>
              <td>100</td>
              <td>1K</td>
              <td align="right">
                <span
                  class="d-flex align-items-center links justify-content-end"
                >
                  <a href="#"
                    ><img src="assets/images/badge.svg" alt="Icon"
                  /></a>
                  <a href="#"
                    ><img src="assets/images/popularity.svg" alt="Icon"
                  /></a>
                  <a href="#"
                    ><img src="assets/images/add-group.svg" alt="Icon"
                  /></a>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span class="d-flex align-items-center">
                  <vue-feather class="userIcon me-2" type="user"></vue-feather>
                  <span class="name">Abc</span>
                </span>
              </td>
              <td>100</td>
              <td>1K</td>
              <td align="right">
                <span
                  class="d-flex align-items-center links justify-content-end"
                >
                  <a href="#"
                    ><img src="assets/images/badge.svg" alt="Icon"
                  /></a>
                  <a href="#"
                    ><img src="assets/images/popularity.svg" alt="Icon"
                  /></a>
                  <a href="#"
                    ><img src="assets/images/add-group.svg" alt="Icon"
                  /></a>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span class="d-flex align-items-center">
                  <vue-feather class="userIcon me-2" type="user"></vue-feather>
                  <span class="name">Abc</span>
                </span>
              </td>
              <td>100</td>
              <td>1K</td>
              <td align="right">
                <span
                  class="d-flex align-items-center links justify-content-end"
                >
                  <a href="#"
                    ><img src="assets/images/badge.svg" alt="Icon"
                  /></a>
                  <a href="#"
                    ><img src="assets/images/popularity.svg" alt="Icon"
                  /></a>
                  <a href="#"
                    ><img src="assets/images/add-group.svg" alt="Icon"
                  /></a>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span class="d-flex align-items-center">
                  <vue-feather class="userIcon me-2" type="user"></vue-feather>
                  <span class="name">Abc</span>
                </span>
              </td>
              <td>100</td>
              <td>1K</td>
              <td align="right">
                <span
                  class="d-flex align-items-center links justify-content-end"
                >
                  <a href="#"
                    ><img src="assets/images/badge.svg" alt="Icon"
                  /></a>
                  <a href="#"
                    ><img src="assets/images/popularity.svg" alt="Icon"
                  /></a>
                  <a href="#"
                    ><img src="assets/images/add-group.svg" alt="Icon"
                  /></a>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span class="d-flex align-items-center">
                  <vue-feather class="userIcon me-2" type="user"></vue-feather>
                  <span class="name">Abc</span>
                </span>
              </td>
              <td>100</td>
              <td>1K</td>
              <td align="right">
                <span
                  class="d-flex align-items-center links justify-content-end"
                >
                  <a href="#"
                    ><img src="assets/images/badge.svg" alt="Icon"
                  /></a>
                  <a href="#"
                    ><img src="assets/images/popularity.svg" alt="Icon"
                  /></a>
                  <a href="#"
                    ><img src="assets/images/add-group.svg" alt="Icon"
                  /></a>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> -->
    <!-- Open Modal -->
    <div
      class="modal fade ideaModal"
      v-if="Object.keys(showNews).length > 0"
      :class="Object.keys(showNews).length > 0 ? 'show' : ''"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="row modal-content g-0 h-100">
          <div class="col-12 col-lg-6 ideaChart">
            <img
              class="d-block w-100 h-100"
              :src="showNews.image_url"
              alt="News"
              title="News Image"
            />
          </div>
          <div
            class="col-12 col-lg-6 d-flex flex-column ideaChart border-start"
          >
            <div class="modal-body p-4 pt-0 border-0">
              <div
                class="d-flex align-items-center justify-content-between modalHeader mb-3 py-3"
              >
                <ul class="d-flex align-items-center mb-0">
                  <li>
                    <a href="javascript:void(0)" class="d-flex me-3"
                      ><vue-feather size="23px" type="share-2"></vue-feather
                    ></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="d-flex"
                      ><vue-feather size="23px" type="printer"></vue-feather
                    ></a>
                  </li>
                </ul>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  @click="showNews = {}"
                ></button>
              </div>
              <ul class="datTime d-flex align-items-center mb-0">
                <li class="me-4">
                  <a class="green" href="portfolios.com">{{(showNews.source != 'FXS') ? showNews.metadata.header : showNews.title}}</a>
                </li>
                <li>{{formatNow(showNews.created,'now',0)}}</li>
              </ul>
              <h2 class="medium my-4 py-2" v-html="showNews.text"></h2>
              <p v-html="showNews.description"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useMainStore } from "@/store";
import moment from "moment";
export default {
  setup() {
    const store = useMainStore();
    return { store };
  },
  data() {
    return {
      showNews: {},
    };
  },
  watch: {
    // showNews(val) {
    //   const el = document.body;
    //   if (Object.keys(val).length > 0) {
    //     if (el.classList.contains("position-fixed")) {
    //       el.classList.remove("position-fixed");
    //     } else {
    //       el.classList.add("position-fixed");
    //     }
    //   } else {
    //     el.classList.remove("position-fixed");
    //   }
    // },
  },
  computed: {
    show_news() {
      if (this.store.popularNews.length > 0) {
        return this.store.popularNews.filter((val) => val.details.length > 0);
      } else {
        return [];
      }
    },
    show_Ideas() {
      if (this.store.popularIdeas.totalRecords > 0) {
        return this.store.popularIdeas.records.splice(0, 3);
      } else {
        return [];
      }
    },
  },
  methods: {
    goToDetailPage(item){
        let title = ''
        if(item.source != 'FXS'){
            title = item.metadata.header
        }else{
            title = item.title
        }
        if(title.includes('-')){
            title = title.replace(/-+/g, '')
        }else{
            let titl = title.replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-')
            if(titl.includes('--')){
                title = titl.replace(/--+/g, '-')
            }else{
                if(item.source != 'FXS'){
                    title = item.metadata.header
                }else{
                     title = item.title
                }
            }
        }
        this.$router.push({
            name: 'insight_detail',
            params : {'id' : item._id,'title':title.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-'),'page' : 1}
        });
    },
    formatNow(date,format,offset){
            if(!date) return 'N/A'
            let d = moment(date, 'YYYY-MM-DD HH:mm:ss')
            let tz = offset != 0 ? offset : -(new Date().getTimezoneOffset())
            d.add(tz, 'minutes')
            return d.fromNow()
        },
    getdata() {
      let formData = {
        page: 1,
        perPage: 15,
      };
      if (this.$route.query.market) {
        formData.tags = [this.$route.query.market];
      }
      this.store
        .callFinancialContent(formData, false)
        .then(() => {})
        .catch((err) => console.log(err.message));
    },
  },
  created() {
    this.getdata();
  },
};
</script>

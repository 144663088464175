<template>
    <div class="row">
        <div class="col-12 mb-4"><h3 class="mb-0">Cross Pairs</h3></div>
        <div class="col-12">
            <div class="table-responsive">
                <table class="table table-sm m-0 border-0 padTable">
                    <thead>
                        <tr align="center" class="border-top-0 medium">
                            <th>Symbol</th>
                            <th>D</th>
                            <th>W</th>
                            <th>M</th>
                            <th>Y</th>
                            <th>Chart</th>
                            <th>Price</th>
                            <th width="17%" align="center">Support Resistance</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td width="17%">
                                <span class="d-flex align-items-center">
                                    <img src="assets/images/market-trend-flag1.png" alt="" /> <span class="medium ctr ps-2">EUR-USD<span class="d-block f-10 regular">EURO / U.S DollarD</span></span>
                                </span>
                            </td>
                            <td class="red">-0.01%</td>
                            <td class="red">-0.71%</td>
                            <td class="red">-1.42%</td>
                            <td class="red">-10.66%</td>
                            <td><img src="assets/images/redChart.png" alt="Chart" /></td>
                            <td width="18%">
                                <span class="d-flex align-items-center gdp"> <span class="f-18 pe-2">1.18816 </span><vue-feather class="red" type="chevron-down"></vue-feather> <span class="red f-12">- 1.45%</span> </span>
                            </td>
                            <td width="17%" align="center"><img src="assets/images/weekdays.png" class="" /></td>
                        </tr>
                        <tr>
                            <td width="17%">
                                <span class="d-flex align-items-center">
                                    <img src="assets/images/market-trend-flag1.png" alt="" /> <span class="medium ctr ps-2">XAU-USD<span class="d-block f-10 regular">Gold / U.S Dollar</span></span>
                                </span>
                            </td>
                            <td class="red">-0.01%</td>
                            <td class="red">-0.71%</td>
                            <td class="red">-1.42%</td>
                            <td class="red">-10.66%</td>
                            <td><img src="assets/images/redChart.png" alt="Chart" /></td>
                            <td width="18%">
                                <span class="d-flex align-items-center gdp"> <span class="f-18 pe-2">1.18816 </span><vue-feather class="red" type="chevron-down"></vue-feather> <span class="red f-12">- 1.45%</span> </span>
                            </td>
                            <td width="17%" align="center"><img src="assets/images/weekdays.png" class="" /></td>
                        </tr>
                        <tr>
                            <td width="17%">
                                <span class="d-flex align-items-center">
                                    <img src="assets/images/market-trend-flag1.png" alt="" /> <span class="medium ctr ps-2">GBP-CHF<span class="d-block f-10 regular">GBP/CHF</span></span>
                                </span>
                            </td>
                            <td class="green">+0.01%</td>
                            <td class="green">+0.71%</td>
                            <td class="green">+1.42%</td>
                            <td class="green">+10.66%</td>
                            <td><img src="assets/images/greenChart.png" alt="Chart" /></td>
                            <td width="18%">
                                <span class="d-flex align-items-center gdp"> <span class="f-18 pe-2">1.18816 </span><vue-feather class="green" type="chevron-down"></vue-feather> <span class="green f-12">- 1.45%</span> </span>
                            </td>
                            <td width="17%" align="center"><img src="assets/images/weekdays.png" class="" /></td>
                        </tr>
                        <tr>
                            <td width="17%">
                                <span class="d-flex align-items-center">
                                    <img src="assets/images/market-trend-flag1.png" alt="" /> <span class="medium ctr ps-2">GBP-CHF<span class="d-block f-10 regular">GBP/CHF</span></span>
                                </span>
                            </td>
                            <td class="green">+0.01%</td>
                            <td class="green">+0.71%</td>
                            <td class="green">+1.42%</td>
                            <td class="green">+10.66%</td>
                            <td><img src="assets/images/greenChart.png" alt="Chart" /></td>
                            <td width="18%">
                                <span class="d-flex align-items-center gdp"> <span class="f-18 pe-2">1.18816 </span><vue-feather class="green" type="chevron-down"></vue-feather> <span class="green f-12">- 1.45%</span> </span>
                            </td>
                            <td width="17%" align="center"><img src="assets/images/weekdays.png" class="" /></td>
                        </tr>
                        <tr>
                            <td width="17%">
                                <span class="d-flex align-items-center">
                                    <img src="assets/images/market-trend-flag1.png" alt="" /> <span class="medium ctr ps-2">XAU-USD<span class="d-block f-10 regular">Gold / U.S Dollar</span></span>
                                </span>
                            </td>
                            <td class="red">-0.01%</td>
                            <td class="red">-0.71%</td>
                            <td class="red">-1.42%</td>
                            <td class="red">-10.66%</td>
                            <td><img src="assets/images/redChart.png" alt="Chart" /></td>
                            <td width="18%">
                                <span class="d-flex align-items-center gdp"> <span class="f-18 pe-2">1.18816 </span><vue-feather class="red" type="chevron-down"></vue-feather> <span class="red f-12">- 1.45%</span> </span>
                            </td>
                            <td width="17%" align="center"><img src="assets/images/weekdays.png" class="" /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
      data() {
        return {

        }
      },
    }
</script>
<template>
    <div class="d-flex align-items-center justify-content-between pt-4">
        <h3 class="mb-0">Trade Ideas</h3>
        <ul class="d-flex align-items-center tradeMenu mb-0">
            <li @click="
              allIdeas = !allIdeas;date = '';mostReadIdeas=false,from = '';to = '';showCalendar = false;
              getIdeasList();
            ">
                <a href="javascript:void(0)"><img src="assets/images/grid.svg" alt="Icon" /></a>
            </li>
            <li @click="
              mostReadIdeas = !mostReadIdeas;
              allIdeas = false;date = '';from = '';to = '';showCalendar = false;
              getIdeasList();
            ">
                <a href="javascript:void(0)"><img src="assets/images/fire.svg" alt="Icon" /></a>
            </li>
            <li @click="setDate('W')">
                <a href="javascript:void(0)"><span class="f-15">W</span></a>
            </li>
            <li @click="setDate('M')">
                <a href="javascript:void(0)"><span class="f-15">M</span></a>
            </li>
            <li>
                <a href="javascript:void(0)" v-if="!showCalendar" @click="showCalendar = !showCalendar">
                    <vue-feather type="calendar"></vue-feather>
                </a>
                <a href="javascript:void(0)" v-else @click="showCalendar = false">
                    <vue-feather type="x"></vue-feather>
                </a>
            </li>
        </ul>
        <Datepicker :range="true" v-model="date" :multiCalendars="true" inline autoApply :multiCalendarsSolo="true"
            :enableTimePicker="false" ref="datepicker" :maxDate="new Date()" v-if="showCalendar"
            @update:modelValue="handleDate" />
    </div>
    <div class="leftSide idea top-30 position-relative" v-if="showIdea.length > 0">
        <div class="card border-0 mb-4" v-for="(item, key) in showIdea">
            <div class="card-body">
                <h4 class="card-title">
                    <a href="javascript:void(0)" @click="() => goToDetailPage(item)">{{ item.title }}</a>
                    <span class="modifyDate">{{ formatDate(item.created) }}</span>
                </h4>
                <img :src="
                  imageLink + '/get/doc?type=TRADE&doc=' + item.document + '&id='+item.user_id
                " class="img-responsive" alt="" />
                <div class="d-flex align-items-center justify-content-between ideas-currency-row my-3">
                    <div class="forexnood d-flex align-items-center" @click="() => goToProfilePage(item.user_id)">
                        <p class="IdeaUsername mb-0">{{item.firstname.charAt(0) || ''}}{{item.lastname.charAt(0) || ''}}
                        </p>
                        <span class="mx-2">{{ item.firstname || "" }} {{ item.lastname || "" }}</span>
                        <!-- <img src="assets/images/badge.jpg" alt="" /> -->
                    </div>
                    <div class="currency-wrap d-flex align-items-center">
                        <span class="currency"><a href="javascript:void(0)">{{ item.symbol || "N/A" }}</a></span>
                        <span class="monthly"><a href="javascript:void(0)">{{ item.time_interval || "N/A" }}</a></span>
                        <span class="short">
                            <a href="javascript:void(0)" class="d-flex align-items-center"
                                :class="SymbolClass[item.position.toLowerCase()]">
                                <vue-feather class="me-1" type="arrow-down-right"></vue-feather>
                                {{ item.position || "N/A" }}
                            </a>
                        </span>
                    </div>
                </div>
                <p>{{ item.description }}</p>
                <div class="smallbtn d-flex align-items-center">
                    <span class="cardEelement">{{ item.classification || "N/A" }}</span>
                    <span class="cardEelement">{{ item.type || "N/A" }}</span>
                </div>
            </div>
            <div class="btm-row d-flex align-items-center justify-content-between border-top mt-4 pt-3">
                <div @click="() => likeIdea(item,'like')">
                    <span><i :class="parseInt(item.is_like) == 0 ? 'fa fa-heart-o' : 'fa fa-heart'"
                            aria-hidden="true"></i>
                        {{ item.likes_count || 0 }}</span>
                </div>
                <div class="righticons">
                    <span @click="() => likeIdea(item,'comment')"><i class="fa fa-comments-o"
                            aria-hidden="true"></i>
                        {{ item.comment_count || 0 }}</span>
                    <span><i class="fa fa-share-alt" aria-hidden="true"></i>
                        {{ item.share_count || 0 }}</span>
                    <span><i class="fa fa-bookmark-o" aria-hidden="true"></i>
                        {{ item.bookmarks_count || 0 }}</span>
                </div>
            </div>
        </div>
        <div class="leftbottonScroll-btn text-center position-absolute">
            <a href="javascript:void(0)" class="grd_btn btn_arrow" @click="showMore = true" v-if="!showMore">
                <vue-feather type="chevron-down"></vue-feather>
            </a>
            <a href="javascript:void(0)" class="grd_btn btn_arrow" @click="showMore = false" v-if="showMore">
                <vue-feather type="chevron-up"></vue-feather>
            </a>
        </div>
    </div>
    <no-record v-else></no-record>
    <div class="modal fade visitModal" :class="[{'show' : showPopup != ''}]">
        <div class="modal-dialog modal-dialog-centered max-600 w-100">
            <div class="modal-content">
                <div class="modal-header pb-0 justify-content-end border-0">
                    <a href="javascript:void(0)" class="close"
                        @click="showPopup = '';loginform = {email: '',password:''}">
                        <vue-feather type="x"></vue-feather>
                    </a>
                </div>
                <div class="modal-body">
                    <div class="loginPortfolio h-100">
                        <h3 class="text-center mb-5">Log into Portfolios</h3>
                        <Form class="login was-validated" @submit="submit">
                            <!--Click submit add class "was-validated"-->
                            <div class="form-group">
                                <label>Email Address</label>
                                <Field type="text" class="form-control" name="Email" rules="required|email"
                                    v-model="loginform.email" />
                                <ErrorMessage as="paragraph" name="Email" class="text-danger mb-0" />
                            </div>
                            <div class="form-group pb-2">
                                <label>Password</label>
                                <Field type="password" class="form-control" name="Password" rules="required"
                                    v-model="loginform.password" />
                                <ErrorMessage as="paragraph" name="Password" class="text-danger mb-0" />
                            </div>
                            <!--  <div class="form-group">
                      <a
                        href="javascript:void(0)"
                        class="grd_btn w-100"
                        data-bs-dismiss="modal"
                        >LOG IN
                      </a>
                    </div> -->
                            <div class="form-group">
                                <button class="grd_btn w-100">LOG IN</button>
                            </div>
                        </Form>
                        <div class="or text-center mb-4 d-none">
                            <p class="mb-0 d-inline-flex">OR</p>
                        </div>
                        <!-- <div class="socialShare d-none">
                    <a
                      href="javascript:void(0)"
                      class="border-btn d-flex align-items-center justify-content-center"
                      ><img
                        class="me-3"
                        src="assets/images/google.svg"
                        alt="Google"
                        title="Continue with Google"
                      />Continue with Google</a
                    >
                    <a
                      href="javascript:void(0)"
                      class="border-btn d-flex align-items-center justify-content-center"
                      ><img
                        class="me-3"
                        src="assets/images/apple.svg"
                        alt="Apple"
                        title="Continue with Apple"
                      />Continue with Apple</a
                    >
                    <a
                      href="javascript:void(0)"
                      class="border-btn d-flex align-items-center justify-content-center"
                      ><img
                        class="me-3"
                        src="assets/images/facebook-cir.svg"
                        alt="Facebook"
                        title="Continue with Facebook"
                      />Continue with Facebook</a
                    >
                  </div> -->
                        <div class="register text-center pb-4">
                            Don’t have an account?
                            <router-link to="/register" class="green underline">Sign Up Here</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useMainStore } from "@/store";
import moment from "moment";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            imageLink: process.env.VUE_APP_API_BASE_URL,
            showMore: false,
            mostReadIdeas: false,
            allIdeas: true,
            from: "",
            to: "",
            date: "",
            showCalendar: "",
            SymbolClass: {
                neutral: "yellowBG",
                buy: "greenBG",
                sell: "redBG",
            },
            showPopup: '',
            loginform: {
                email: "",
                password: "",
            },
        };
    },
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    computed: {
        showIdea() {
            if (
                this.store.tradeIdeasList.totalRecords &&
                this.store.tradeIdeasList.totalRecords > 0
            ) {
                if (!this.showMore) {
                    return this.store.tradeIdeasList.records.slice(0, 6);
                } else {
                    return this.store.tradeIdeasList.records.slice(0, 9);
                }
            } else {
                return [];
            }
        },
    },
    methods: {
        goToProfilePage(id) {
            if (this.store.user.profile) {
                if (parseInt(this.store.user.profile.user_id) != parseInt(id)) {
                    this.$router.push({
                        name: 'profile',
                        query: { 'id': id }
                    });
                } else {
                    this.$router.push({
                        name: 'profile',
                    });
                }
            } else {
                this.$router.push({
                    name: 'profile',
                    query: { 'id': id }
                });
            }
        },
        submit() {
            let form = {
                'email': this.loginform.email,
                'password': this.loginform.password,
                'from': 'TradeIdeaList'
            }
            this.store.login(form, true, this);
        },
        likeIdea(item, type) {
            if (Object.keys(this.store.user).length > 0 && this.store.user.profile) {
                this.showPopup = ''
                if (type == 'like') {
                    this.callUpdateTrade(item)
                } else {
                    this.goToDetailPage(item)
                }
            } else {
                this.showPopup = item.trade_id
            }
        },
        callUpdateTrade(item) {
            let form = {
                "type": "TRADE", "is_like": 1, "user_id": this.store.user.profile.user_id, "target_id": item.trade_id
            }
            if (parseInt(item.is_like) == 1) {
                form['is_like'] = 0
            }
            if (this.store.tradeIdeasList.totalRecords) {
                this.store.tradeIdeasList.records.forEach(val => {
                    if (parseInt(val.trade_id) == parseInt(item.trade_id)) {
                        if (parseInt(val.is_like) == 1) {
                            val.is_like = 0
                            val.likes_count = parseInt(val.likes_count) - 1
                        } else {
                            val.is_like = 1
                            val.likes_count = parseInt(val.likes_count) + 1
                        }
                    }
                })
            }
            this.store.callUpdateIdeas(form, true)
        },
        goToDetailPage(item) {
            let title = item.title
            if (title.includes('-')) {
                title = title.replace(/-+/g, '')
            } else {
                let titl = title.replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-')
                if (titl.includes('--')) {
                    title = titl.replace(/--+/g, '-')
                } else {
                    title = item.title
                }
            }
            this.$router.push({
                name: 'details',
                params: { 'id': item.trade_id, 'title': title.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-'), 'page': 1 }
            });
            /*/ +/g, '-'*/
        },
        handleDate() {
            if ((this.date[0], this.date[1])) {
                this.showCalendar = false;
                this.date = ''
                this.mostReadIdeas = false
                this.allIdeas = false
                //this.$refs.datepicker.closeMenu()
                this.from = moment(this.date[0]).format("YYYY-MM-DD");
                this.to = moment(this.date[1]).format("YYYY-MM-DD");
                this.getIdeasList();
            }
        },
        setDate(type) {
            if (type == "W") {
                this.from = moment(moment().subtract(7, "days")).format("YYYY-MM-DD");
                this.to = moment().format("YYYY-MM-DD");
            } else if (type == "M") {
                this.from = moment(moment().subtract(1, "month")).format("YYYY-MM-DD");
                this.to = moment().format("YYYY-MM-DD");
            }
            this.getIdeasList();
        },
        formatDate(val) {
            return moment(val).format("DD MMM");
        },
        getIdeasList() {
            let formData = {
                page: 1,
                perPage: 11,
                sort: { field: "trade_ideas.created", type: "DESC" },
            };
            if (this.$route.query.market) {
                formData.tags = [this.$route.query.market];
            }
            if (this.allIdeas) {
                formData["sort"] = { field: "trade_ideas.created", type: "DESC" };
            }
            if (this.mostReadIdeas) {
                formData["sort"] = { field: "trade_ideas.view_count", type: "DESC" };
            }
            if (this.from && this.to) {
                formData["start_date"] = this.from;
                formData["end_date"] = this.to;
            }
            if (this.store.user.profile) {
                formData['logged_user_id'] = this.store.user.profile.user_id
            }
            this.store
                .callTradeIdeasList(formData, false)
                .then((response) => { })
                .catch();
        },
    },
    created() {
        this.getIdeasList();
    },
};
</script>
<style scoped>
.modal.fade.show {
    opacity: 1;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 100%;
    overflow: auto;
}
</style>

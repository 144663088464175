<template>
    <div class="row">
        <div class="col-12 col-lg-6 mb-4 mb-lg-0">
            <h3 class="mb-4 d-flex align-items-center">Correlation <span class="themecolor ms-4">Volatility</span></h3>
            <div class="customChartbar"><img src="assets/images/zoomChart.png" alt="Icon" class="chartVrticle" /></div>
        </div>
        <div class="col-12 col-lg-6">
            <h3 class="mb-3 d-flex align-items-center">Sentiments <span class="themecolor ms-4">High/Low Screener</span></h3>
            <ul class="toplinks d-flex align-items-center">
                <li><a href="#" class="active">Pivot</a></li>
                <li><a href="#">Fibonacci</a></li>
                <li><a href="#">Std Deviation</a></li>
                <li><a href="#">Bell Curve</a></li>
                <!-- <li><a href="#">Voting</a></li> -->
            </ul>
            <div class="customChartbar"><img src="assets/images/colorlinechart.png" alt="Icon" class="chartVrticle" /></div>
        </div>
    </div>
</template>
<script>
    export default {
      data() {
        return {

        }
      },
    }
</script>